import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useContext, useCallback, useState, useEffect } from "react";
import { notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHistory } from "react-router-dom";
import TitleBar from "./TitleBar";
import { ArrowRightOutlined } from "@ant-design/icons";
import { AdminService } from "src/resources/AdminService";
import { GlobalContext } from "../context/GlobalContext";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const { studentId, setStep, step } = useContext(GlobalContext);
  const [initialRender, setInitialRender] = useState(true);
  const [parents, setParents] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStudentsParents(studentId).then((data) => {
        setParents(data.data);
        console.log(data.data);
      });
    }
  }, [initialRender, studentId]);

  useEffect(() => {
    if (step === -1) {
      router.push("/");
    }
    setStep(5);
  }, [router, setStep, step]);

  const handleSubmit = useCallback(
    async function (values) {
      //    event.preventDefault();
      setLoading(true);
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      try {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setLoading(false);
          return;
        }

        const obj = {
          name: values.fullname,
          phone: values.phone,
          email: values.email,
          address: values.address,
          subs: values.subs,
          suburb: values.suburb,
          postcode: values.postcode,
          state: values.state,
          country: values.country,
          studentId,
        };

        let response = await AdminService.finalizeOrder({
          ...obj,
          payment: {
            payment_method_id: paymentMethod.id,
          },
        });

        // 3D Secure
        while (response.status === 402) {
          const { paymentIntent, error } = await stripe.handleCardAction(
            response.data.token
          );
          if (error) {
            setLoading(false);
            // unable to pass 3Ds
            return;
          }

          response = await AdminService.finalizeOrder({
            ...obj,
            payment: {
              payment_intent_id: paymentIntent.id,
            },
          });
        }

        const ok = response.status === 200 || response.status === 204;
        if (ok) {
          notification.success({
            message: "Success!",
            description: "Thank you for your payment!",
          });

          form.resetFields();
          router.replace("/home/step7");
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements, form, router]
  );
  const parentChanged = (p) => {
    console.log(p);
    if (p === 2) {
      form.resetFields();
    } else {
      const par = parents[p];
      form.setFieldsValue({ ...par });
    }
  };
  return (
    <div className="max-w-full  mx-auto lg:max-w-4xl bg-f5f5f5  p-2 lg:p-6 lg:border lg:border-gray-300 ">
      <TitleBar title="BILLING INFORMATION AND PAYMENT OF APPLICATION FEE ($100)" />
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          paddingRight: "10px",
        }}
      >
        <Form
          onFinish={handleSubmit}
          form={form}
          style={{ maxWidth: "700px" }}
          initialValues={{
            country: "Australia",
            state: "NSW",
            subs: true,
          }}
        >
          {parents && parents.length > 0 && (
            <Form.Item label="Billing info same with">
              <Select
                defaultValue={2}
                placeholder="Please select"
                onChange={parentChanged}
              >
                <Select.Option key="2" value={2}>
                  New Person
                </Select.Option>
                {parents.map((p, index) => (
                  <Select.Option key={index} value={index}>
                    {p.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="fullname"
            rules={[{ required: true, message: "Fullname is required" }]}
          >
            <Input placeholder="Full name" />
          </Form.Item>
          <Form.Item
            name="phone"
            style={lg && { display: "inline-block", width: "calc(50% - 8px)" }}
            rules={[{ required: true, message: "Phone is required" }]}
          >
            <Input
              // international
              // defaultCountry="AU"
              // value={phone}
              // onChange={(a) => setPhone(a)}
              placeholder="Phone"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Invalid email address" },
            ]}
            style={
              lg && {
                display: "inline-block",
                width: "calc(50% - 0px)",
                margin: "0 0 0 8px",
              }
            }
          >
            <Input placeholder="Please Type Email" />
          </Form.Item>
          <Form.Item name="address">
            <Input placeholder="Please Type Address" />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="suburb"
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              rules={[{ required: true, message: "Address is required" }]}
            >
              <Input placeholder="Suburb" />
            </Form.Item>

            <Form.Item
              name="postcode"
              rules={[{ required: true, message: "Postcode is required" }]}
              style={{
                display: "inline-block",
                width: "calc(50% - 0px)",
                margin: "0 0 0 8px",
              }}
            >
              <Input placeholder="Postcode" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Country is required" }]}
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            >
              <Input allowClear placeholder="Type Country" />
            </Form.Item>

            <Form.Item
              name="state"
              rules={[{ required: true, message: "State is required" }]}
              style={{
                display: "inline-block",
                width: "calc(50% - 0px)",
                margin: "0 0 0 8px",
              }}
            >
              <Input allowClear placeholder="Type State" />
            </Form.Item>
          </Form.Item>{" "}
          <h4 className="text-base">
            Please Type Your Credit/Debit Card Details
          </h4>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "18px",
                  fontFamily: "monospace",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "red",
                },
              },
            }}
            className="st-form"
          />
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!stripe || !elements}
            block
            style={{ maxWidth: "500px" }}
            size="large"
          >
            Pay Now $100
          </Button>
          <Button onClick={() => router.push("/home/step7")}>
            <ArrowRightOutlined />
          </Button>
          <img
            src={require("../../assets/images/secure-stripe.png").default}
            alt="secure"
            className="max-w-lg"
          />
        </Form>
      </div>
    </div>
  );
};
export default CheckoutForm;
