import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const CampusContacts = ({ id }) => {
  const [initialRender, setInitialRender] = useState(true);
  const [messages, setMessages] = useState(null);
  useEffect(() => {
    setInitialRender(false);
    AdminService.getContactMessages(id).then((data) => {
      console.log(data.data);
      setMessages(data.data);
    });
  }, [id, initialRender]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
  ];
  return (
    <div>
      <Table
        loading={messages === null}
        dataSource={messages}
        bordered
        columns={columns}
        size="small"
      />
    </div>
  );
};

export default CampusContacts;
