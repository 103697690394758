import { ArrowRightOutlined, ClearOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { GlobalContext } from "../context/GlobalContext";
import TitleBar from "./TitleBar";

const Step2Form = () => {
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const [goster, setGoster] = useState(false);
  const [goster2, setGoster2] = useState(false);

  const router = useHistory();

  const { studentId, setStep, step } = useContext(GlobalContext);
  useEffect(() => {
    if (step === -1) {
      router.push("/");
    }
    setStep(6);
  }, [router, setStep, step]);
  const onFinish = (values) => {
    console.log(values);
    values = { ...values, studentId };
    if (values.prompts.length + values.learns.length === 0) {
      router.push("/home/step8");
    } else {
      AdminService.newFeedback(values).then((data) => {
        console.log(data.data);
        notification.success({
          message: "Success",
          description: "Step-7 completed",
        });
        router.replace("/home/step8");
      });
    }
  };
  const onReset = () => {
    form.resetFields();
  };

  const prompts = [
    { label: "Reputation of Amity College", value: 1 },
    { label: "Academic excellence", value: 2 },
    { label: "Extracurricular activities", value: 3 },
    { label: "School values and ethos", value: 4 },
    { label: "Pastoral care programs", value: 5 },
    { label: "Continuing family tradition", value: 6 },
    { label: "Close to home", value: 7 },
    { label: "Other", value: 8 },
  ];
  const learns = [
    { label: "We are current Amity College parents", value: 1 },
    { label: "Family/friends", value: 2 },
    { label: "Past students", value: 3 },
    { label: "School guides and listings", value: 4 },
    { label: "Newspaper advertisement", value: 5 },
    { label: "Social media advertisement", value: 6 },
    { label: "School’s webpage", value: 7 },
    { label: "Other", value: 8 },
  ];
  const valueChanged = (v) => {
    setGoster(v.findIndex((a) => a === 8) !== -1 ? true : false);
  };
  const valueChanged2 = (v) => {
    setGoster2(v.findIndex((a) => a === 8) !== -1 ? true : false);
  };
  return (
    <div className=" max-w-full mx-auto  lg:max-w-4xl bg-fafafa p-2 lg:p-6 lg:border lg:border-gray-300">
      <div className="text-center">
        <img
          src={require("../../assets/images/check.png").default}
          alt="check"
          className="w-24 mb-8 "
          style={{ marginLeft: "calc(50% - 50px)" }}
        />

        <p className="mb-10 text-2xl block text-green-500 font-bold">
          Application Submitted Successfully!
        </p>
      </div>
      <TitleBar title="PARENT / GUARDIAN FEEDBACK" />
      <div
        style={{
          maxWidth: "calc(100% - 16px)",
          overflowWrap: "anywhere",
          overflow: "hidden",
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          layout={lg ? "horizontal" : "inline"}
          {...GlobalVariables.formLayout}
          initialValues={{ prompts: [], learns: [] }}
        >
          <Form.Item {...GlobalVariables.tailLayout}>
            <p>
              <b> What has prompted you to apply for enrolment?</b>
              <br />
              (you may tick more than one box)
            </p>
          </Form.Item>
          <Form.Item name="prompts" {...GlobalVariables.tailLayout}>
            <Checkbox.Group onChange={valueChanged}>
              <Row>
                {prompts.map((p) => (
                  <Col span={24} key={p.value}>
                    <Checkbox value={p.value}>{p.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          {goster && (
            <Form.Item
              label="Please specify"
              name="pSpecify"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Please type " />
            </Form.Item>
          )}
          <Form.Item {...GlobalVariables.tailLayout}>
            <p>
              <b>How did you learn about Amity College?</b>
              <br />
              (you may tick more than one box)
            </p>
          </Form.Item>
          <Form.Item name="learns" {...GlobalVariables.tailLayout}>
            <Checkbox.Group onChange={valueChanged2}>
              <Row>
                {learns.map((p) => (
                  <Col span={24} key={p.value}>
                    <Checkbox value={p.value}>{p.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          {goster2 && (
            <Form.Item
              label="Please specify"
              name="lSpecify"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Please type" />
            </Form.Item>
          )}
          <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
            <Space>
              <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit">
                Next <ArrowRightOutlined />
              </Button>
              <Button onClick={() => router.push("/home/step8")}>
                <ArrowRightOutlined />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Step2Form;
