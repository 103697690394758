import {
  ArrowRightOutlined,
  ClearOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Popover,
  Select,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { GlobalContext } from "../context/GlobalContext";
import TitleBar from "./TitleBar";

const Step4Form = () => {
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const router = useHistory();
  const [isFund, setIsFund] = useState(false);
  const [isAmb, setIsAmb] = useState(false);
  const { studentId, setStep, step } = useContext(GlobalContext);
  useEffect(() => {
    if (step === -1) {
      router.push("/");
    }
    setStep(3);
  }, [router, setStep, step]);
  const onFinish = (values) => {
    console.log(values);
    values = { ...values, studentId };
    AdminService.newMedical(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Step-4 completed",
      });
      router.replace("/home/step5");
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const fundChange = (s) => {
    console.log(s);
    setIsFund(s === "Yes" ? true : false);
  };
  const ambChange = (s) => {
    console.log(s);
    setIsAmb(s === "Yes" ? true : false);
  };
  const content = (
    <div>
      <img
        src={require("../../assets/images/medicare.jpg").default}
        alt="medicare"
      />
    </div>
  );

  return (
    <div className="max-w-full mx-auto  lg:max-w-4xl bg-f5f5f5  p-2 lg:p-6 lg:border lg:border-gray-300 ">
      <TitleBar title="MEDICAL INFORMATION" />
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          paddingRight: "10px",
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          layout={lg ? "horizontal" : "inline"}
          {...GlobalVariables.formLayout}
        >
          <Form.Item
            label={
              <span>
                Medicare Card No{" "}
                <Popover content={content} placement="bottomLeft">
                  <InfoCircleTwoTone />
                </Popover>{" "}
              </span>
            }
            required
          >
            <Form.Item
              name="medicare"
              style={{ width: "180px", display: "inline-block" }}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Medicare Card No" />
            </Form.Item>
            <Form.Item
              name="medicareRef"
              style={{ width: "80px", display: "inline-block" }}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Ref No" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            required
            label={
              <span>
                Does the student have
                <br /> private health fund cover?
              </span>
            }
          >
            <Form.Item
              name="fundCover"
              style={{ display: "inline-block" }}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                onChange={fundChange}
                placeholder="Please Select"
                style={{ width: 150 }}
              >
                <Select.Option value="No">No</Select.Option>
                <Select.Option value="Yes">Yes</Select.Option>
              </Select>
            </Form.Item>
            {isFund && (
              <Form.Item name="fundCoverNo" style={{ display: "inline-block" }}>
                <Input placeholder="Type Cover No" />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            required
            label={
              <span>
                Is the student covered
                <br /> by ambulance cover?
              </span>
            }
          >
            <Form.Item
              name="ambulanceCover"
              style={{ display: "inline-block" }}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                onChange={ambChange}
                placeholder="Please Select"
                style={{ width: 150 }}
              >
                <Select.Option value="No">No</Select.Option>
                <Select.Option value="Yes">Yes</Select.Option>
              </Select>
            </Form.Item>
            {isAmb && (
              <Form.Item name="ambCoverNo" style={{ display: "inline-block" }}>
                <Input placeholder="Type Cover No" />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name="doctorName"
            label="Name of Family Doctor"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Type Family Doctor Name" />
          </Form.Item>
          <Form.Item
            name="doctorPhone"
            label="Phone of Family Doctor"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Type Family Doctor Phone" />
          </Form.Item>
          <Form.Item
            name="doctorAddress"
            label="Clinic Address"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Type Family Doctor Address" />
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout}>
            <p className="font-semibold">
              Your child’s immunisation record MUST be up to date. Please
              provide a copy of the immunisation report.
            </p>
          </Form.Item>
          <Form.Item
            name="allergies"
            label="Allergies"
            help="Please specify any allergies suffered by the student"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Type Allergies" />
          </Form.Item>
          <Form.Item
            name="medicalProblems"
            label="Medical Problems"
            help="Please specify medical problems the school should be aware of including any daily medication necessary for the student"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Type medical problems" />
          </Form.Item>

          <Form.Item {...GlobalVariables.tailLayout} className="mt-2">
            <TitleBar title="EMERGENCY CONTACT DETAILS" />
            <p>
              Please nominate two people who may be contacted in the event of an
              emergency, where parents cannot be contacted. Ideally, the contact
              people should be those who live in close proximity to the College.
            </p>
            <Divider orientation="left">1st EMERGENCY CONTACT</Divider>
          </Form.Item>
          <Form.Item
            label="Contact Name"
            name="e1ContactName"
            className="-mt-6"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Pleace type name" />
          </Form.Item>
          <Form.Item
            label="Relationship to Child"
            name="e1Relation"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Pleace type relationship" />
          </Form.Item>
          <Form.Item
            label="Mobile Phone"
            name="e1Mobile"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Pleace type mobile phone number" />
          </Form.Item>
          <Form.Item label="Home Phone" name="e1HomePhone">
            <Input placeholder="Pleace type home phone number" />
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout} className="mt-2">
            <Divider orientation="left">2nd EMERGENCY CONTACT</Divider>
          </Form.Item>
          <Form.Item
            label="Contact Name"
            name="e2ContactName"
            className="-mt-6"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Pleace type name" />
          </Form.Item>
          <Form.Item
            label="Relationship to Child"
            name="e2Relation"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Pleace type relationship" />
          </Form.Item>
          <Form.Item
            label="Mobile Phone"
            name="e2Mobile"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Pleace type mobile phone number" />
          </Form.Item>
          <Form.Item label="Home Phone" name="e2HomePhone">
            <Input placeholder="Pleace type home phone number" />
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
            <Space>
              <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit">
                Next <ArrowRightOutlined />
              </Button>
              <Button onClick={() => router.push("/home/step5")}>
                <ArrowRightOutlined />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Step4Form;
