import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Layout, Menu, Row, Tag } from "antd";
import {
  ArrowRightOutlined,
  DashboardOutlined,
  HeartOutlined,
  HomeOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";
import AdminPage from "../components/admin/AdminPage";
import Users from "src/components/admin/Users";
import StudentDetails from "src/components/admin/StudentDetails";
import Schools from "src/components/admin/Schools";
import Students from "src/components/admin/Students";
import Documents from "src/components/admin/Documents";
import AdminContacts from "src/components/admin/Contacts";
import { GlobalContext } from "src/components/context/GlobalContext";
import { AdminRoute, SchoolAdminRoute } from "src/resources/PrivateRoute";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const location = useLocation();
  const router = useHistory();
  const { role } = useContext(GlobalContext);
  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    role && (
      <Layout>
        <Header
          style={{
            height: "75px",
            lineHeight: "75px",
            position: lg ? "fixed" : "unset",
            zIndex: 1,
            width: "100%",
            backgroundColor: "white",
            borderBottom: "1px solid #ccc",
            boxShadow: "1px 1px 10px #ddd",
          }}
        >
          <Row justify={!sm ? "start" : "space-between"} align="middle">
            <Col>
              <Button
                icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                size="large"
                onClick={() => setIsSider(!isSider)}
              />
            </Col>
            <Col>
              <img
                onClick={() => router.push("/home/homepage")}
                src={require("../assets/images/logo.png").default}
                alt="logo"
                style={{
                  maxWidth: "120px",
                  marginLeft: !sm ? "20px" : "",
                  cursor: "pointer",
                }}
              />
            </Col>
            {lg && (
              <Col>
                <Button
                  onClick={() => {
                    localStorage.clear();
                    router.push("/");
                  }}
                  type="primary"
                  icon={<LogoutOutlined />}
                >
                  Log Out
                </Button>
              </Col>
            )}
          </Row>
        </Header>
        <Layout>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
            collapsible
            collapsed={isSider}
            theme="light"
            className={!lg ? "mobil-sider" : "desktop-sider"}
          >
            <br />
            <Tag
              color="blue"
              style={{
                width: "calc(100% - 40px)",
                textAlign: "center",
                margin: "5px 20px 0 20px",
              }}
            >
              Admin Menu
            </Tag>
            <Menu
              className="amenu"
              onSelect={() => {
                !lg && setIsSider(true);
              }}
              theme="light"
              mode="inline"
              defaultSelectedKeys={[location.pathname.split("/").pop()]}
            >
              <Menu.Item key="adminpage" icon={<DashboardOutlined />}>
                <Link to="/admin/adminpage">Dashboard</Link>
              </Menu.Item>
              {role === "Admin" && (
                <Menu.Item key="schools" icon={<HomeOutlined />}>
                  <Link to="/admin/schools">Schools</Link>
                </Menu.Item>
              )}
              <Menu.Item key="students" icon={<HomeOutlined />}>
                <Link to="/admin/students">Students</Link>
              </Menu.Item>

              {role === "Admin" && (
                <Menu.Item key="users" icon={<UserOutlined />}>
                  <Link to="/admin/users">Users</Link>
                </Menu.Item>
              )}
              <Menu.Item key="contacts" icon={<MailOutlined />}>
                <Link to="/admin/contacts">Contacts</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Content
            style={{
              marginTop: lg ? "75px" : undefined,
              padding: "10px",
              borderLeft: "1px solid #ddd",
              minHeight: "calc(100vh - 150px)",
            }}
          >
            <Switch>
              <Route path="/admin" exact>
                <Redirect to="/admin/adminpage" />
              </Route>
              <SchoolAdminRoute path="/admin/adminpage" component={AdminPage} />
              <SchoolAdminRoute
                path="/admin/students/:id/details"
                component={StudentDetails}
              />
              <SchoolAdminRoute
                path="/admin/students/:id/documents"
                component={Documents}
              />
              <AdminRoute path="/admin/schools" component={Schools} />
              <SchoolAdminRoute path="/admin/students" component={Students} />
              <AdminRoute path="/admin/users" component={Users} />
              <SchoolAdminRoute
                path="/admin/contacts"
                component={AdminContacts}
              />
              <Route>
                <Redirect to="/404" />
              </Route>
            </Switch>
          </Content>
        </Layout>
        <Footer className="footer">
          <div>
            <p className="text-small text-gray-400 text-center">
              <span className="text-emerald-600 font-semibold">
                &copy; Amity College
              </span>
              {", "}2021 . All rights reserved. <br />
              Developed with <HeartOutlined /> React
            </p>
          </div>
        </Footer>
      </Layout>
    )
  );
};

export default AdminLayout;
