import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import CheckoutForm from "./CheckoutForm";
import Contacts from "./Contacts";

const Step6 = () => {
  const stripePromise = useMemo(
    () =>
      loadStripe(
        "pk_test_51JBXTjE8cXeCmmwbFKaTWIqMXshpGqkklyiaTWUvebeZrdZc999fblpssMaDamjk8FtMayhuzkVzg0G3GRUFAVob007gYJ4aI6"
      ),
    []
  );
  return (
    <div>
      <div className="py-6  overflow-hidden lg:pb-24">
        <img
          className="relative mx-auto"
          width={150}
          src={require("../../assets/images/logo.png").default}
          alt=""
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-sky-500 ">
              APPLICATION FOR ENROLMENT
            </h2>
          </div>

          <div className="relative mt-12">
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
            <br />
            <br />
            <Contacts />
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Step6;
