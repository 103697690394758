import { PrinterOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Row, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { AdminService } from "src/resources/AdminService";
import { Countries } from "src/resources/countries";
import { languages } from "src/resources/languages";
import { religions } from "src/resources/religions";

const StudentDetails = ({ sid = 0 }) => {
  const location = useLocation();
  const router = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  useEffect(() => {
    AdminService.getStudentDetails(sid === 0 ? id : sid).then((data) => {
      console.log(data.data);
      setItem(data.data);
    });
  }, [id, sid]);

  const getCountryName = (code) => {
    return Countries[Countries.findIndex((c) => c.code === code)]["name"];
  };
  const getReligionName = (code) => {
    return religions[religions.findIndex((c) => c.code === code)]["name"];
  };
  const getLanguageName = (code) => {
    return languages[languages.findIndex((c) => c.code === code)]["name"];
  };
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} className="max-w-6xl mx-auto">
        <div className="bg-white border border-gray-300 p-4 space-y-4">
          {/* ##################STUDENT DETAILS####################### */}

          <Row gutter={[16, 16]}>
            <Col span={4}>
              <img
                src={process.env.REACT_APP_FILES_BASEURL + item.photo}
                alt="student"
                width="100%"
              />
            </Col>
            <Col span={20}>
              <Descriptions title="Student Details" size="small" bordered>
                <Descriptions.Item label="Family Name">
                  {item.familyName}
                </Descriptions.Item>
                <Descriptions.Item label="Given Name">
                  {item.givenName}
                </Descriptions.Item>
                <Descriptions.Item label="Middle Name(s)">
                  {item.middleName}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth">
                  {moment(item.dob).format("DD-MM-YY")}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">
                  {item.gender}
                </Descriptions.Item>
                <Descriptions.Item label="Country Of Birth">{`${getCountryName(
                  item.cob
                )} (${item.cob})`}</Descriptions.Item>
                <Descriptions.Item label="Ethnic B.">
                  {item.ethnic}
                </Descriptions.Item>
                <Descriptions.Item label="Religion">{`${getReligionName(
                  item.religion
                )} (${item.religion})`}</Descriptions.Item>
                <Descriptions.Item label="Lang. at Home">
                  {item.languages.map((l) => (
                    <Tag color="blue" key={l.id}>
                      {`${getLanguageName(l.language)} (${l.language})`}
                    </Tag>
                  ))}
                </Descriptions.Item>

                <Descriptions.Item label="Campus Applied">
                  {item.campus}
                </Descriptions.Item>
                <Descriptions.Item label="App.to Year">
                  {item.toYear}
                </Descriptions.Item>
                <Descriptions.Item label="Start Date">
                  {moment(item.startDate).format("DD-MM-YY")}
                </Descriptions.Item>
                <Descriptions.Item label="Indigenous Status">
                  {item.indigenous}
                </Descriptions.Item>
                <Descriptions.Item label="Cit. / Visa Status">
                  {item.status}
                </Descriptions.Item>
                {item.status === "Temporary" && (
                  <>
                    <Descriptions.Item label="Passport No">
                      {item.passportNo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Visa Class">
                      {item.subclass}
                    </Descriptions.Item>
                    <Descriptions.Item label="Visa Expires">
                      {moment(item.expires).format("DD-MM-YY")}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            </Col>
          </Row>
          {/* ##################EXPERIENCE####################### */}

          <Descriptions
            title="Student's Schooling Experience"
            size="small"
            bordered
          >
            <Descriptions.Item label="Current School/Grade">
              {item.experience.currentSchool} / {item.experience.currentGrade}
            </Descriptions.Item>
            <Descriptions.Item label="1. Prev. School/Grade">
              {item.experience.preSchool1} / {item.experience.preSchool1Grade}
            </Descriptions.Item>
            <Descriptions.Item label="2. Prev. School/Grade">
              {item.experience.preSchool2} / {item.experience.preSchool2Grade}
            </Descriptions.Item>
            <Descriptions.Item label="Child's achievements">
              {item.experience.achievements}
            </Descriptions.Item>
            <Descriptions.Item label="is Suspended?">
              {item.experience.isSuspended}
            </Descriptions.Item>
            {!item.experience.isSuspended && (
              <Descriptions.Item label="Provide Details">
                {item.experience.suspendDetails}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Academic Experience">
              {item.experience.academically}
            </Descriptions.Item>
            <Descriptions.Item label="Social Experience">
              {item.experience.behaviorally}
            </Descriptions.Item>
            <Descriptions.Item label="Special circumstances">
              {item.experience.circumstances}
            </Descriptions.Item>
          </Descriptions>
          {/* ##################PARENTS####################### */}
          {item.parents.map((parent, index) => (
            <div key={index}>
              <Descriptions
                key={index}
                title={`Parent-${index + 1} Details`}
                size="small"
                bordered
              >
                <Descriptions.Item label="Relation">
                  {parent.relation}
                </Descriptions.Item>
                {parent.relation === "Guardian" && (
                  <Descriptions.Item label="Next of Kin">
                    {parent.nok}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Given Name">
                  {parent.title}.{parent.givenName}
                </Descriptions.Item>
                <Descriptions.Item label="Family Name">
                  {parent.familyName}
                </Descriptions.Item>
                <Descriptions.Item label="Amity Graduate">
                  {parent.graduate}
                </Descriptions.Item>
                {parent.graduate && (
                  <Descriptions.Item label="Graduation Year">
                    {parent.graclass}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Address">
                  {parent.address}
                </Descriptions.Item>
                <Descriptions.Item label="Suburb">
                  {parent.suburb}
                </Descriptions.Item>
                <Descriptions.Item label="State">
                  {parent.state}
                </Descriptions.Item>
                <Descriptions.Item label="PostCode">
                  {parent.postcode}
                </Descriptions.Item>
                <Descriptions.Item label="Moble Phone">
                  {parent.mPhone}
                </Descriptions.Item>
                <Descriptions.Item label="Home Phone">
                  {parent.hPhone}
                </Descriptions.Item>
                <Descriptions.Item label="Work Phone">
                  {parent.wPhone}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {parent.mail}
                </Descriptions.Item>
                <Descriptions.Item label="Country Of Birth">{`${getCountryName(
                  parent.cob
                )} (${parent.cob})`}</Descriptions.Item>
                <Descriptions.Item label="Ethnic B.">
                  {parent.ethnic}
                </Descriptions.Item>

                <Descriptions.Item label="Lang. at Home">
                  {parent.languages.map((l) => (
                    <Tag color="blue" key={l.id}>
                      {`${getLanguageName(l.language)} (${l.language})`}
                    </Tag>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item label="Highest year of pri. or sec.">
                  {parent.prisec}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Highest qualification
"
                >
                  {parent.diploma}
                </Descriptions.Item>
                <Descriptions.Item label="Occupation">
                  {parent.occupation}
                </Descriptions.Item>
                <Descriptions.Item label="Occupation Group">
                  {parent.ogroup}
                </Descriptions.Item>
                <Descriptions.Item label="Company/Employer Name">
                  {parent.company}
                </Descriptions.Item>
              </Descriptions>
            </div>
          ))}
          {/* ##################FAMILY####################### */}

          <Descriptions title="Family Details" size="small" bordered>
            <Descriptions.Item label="Child Lives With">
              {item.family.liveswith}
            </Descriptions.Item>
            <Descriptions.Item label="Custody restrictions">
              {item.family.custody}
            </Descriptions.Item>
          </Descriptions>
          {item.family.siblings.map((s, index) => (
            <Descriptions
              key={index}
              title={`Sibling-${index + 1} Details`}
              size="small"
              bordered
            >
              <Descriptions.Item label="Name">{s.name}</Descriptions.Item>
              <Descriptions.Item label="Age">{s.age}</Descriptions.Item>
              <Descriptions.Item label="Grade">{s.grade}</Descriptions.Item>
              <Descriptions.Item label="S.C.Attending">
                {s.attending}
              </Descriptions.Item>
            </Descriptions>
          ))}
          <Descriptions title="Medical Details" size="small" bordered>
            <Descriptions.Item label="Medicare Card No">
              {item.medical.medicare}-{item.medical.medicareRef}
            </Descriptions.Item>
            <Descriptions.Item label="Health fund cover">
              {item.medical.fundCover}
            </Descriptions.Item>
            <Descriptions.Item label="H.Fund Cover No">
              {item.medical.fundCoverNo || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Ambulance cover">
              {item.medical.ambulanceCover}
            </Descriptions.Item>
            <Descriptions.Item label="Ambulance Cover No">
              {item.medical.ambCoverNo || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Name of Family Doctor">
              {item.medical.doctorName}
            </Descriptions.Item>
            <Descriptions.Item label="Phone of Family Doctor">
              {item.medical.doctorPhone}
            </Descriptions.Item>
            <Descriptions.Item label="Clinic Address">
              {item.medical.doctorAddress}
            </Descriptions.Item>
            <Descriptions.Item label="Allergies">
              {item.medical.allergies}
            </Descriptions.Item>
            <Descriptions.Item label="Medical Problems">
              {item.medical.medicalProblems}
            </Descriptions.Item>
          </Descriptions>

          {item.medical.emergencies.map((e, index) => (
            <Descriptions
              key={index}
              title={`Emergency Contact-${index + 1}`}
              size="small"
              bordered
            >
              <Descriptions.Item label="Contact Name">
                {e.contactName}
              </Descriptions.Item>
              <Descriptions.Item label="Relationship to Child">
                {e.relation}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Phone">
                {e.mobilePhone}
              </Descriptions.Item>
              <Descriptions.Item label="Home Phone">
                {e.homePhone}
              </Descriptions.Item>
            </Descriptions>
          ))}
        </div>
      </div>
    );
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    item && (
      <div>
        <ComponentToPrint ref={componentRef} />
        <br />
        <Button
          size="large"
          icon={<PrinterOutlined />}
          type="primary"
          onClick={handlePrint}
        >
          Print this Application
        </Button>
      </div>
    )
  );
};

export default StudentDetails;
