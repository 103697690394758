import { ArrowRightOutlined, ClearOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Select, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { SchoolYears } from "src/resources/Years";
import { GlobalContext } from "../context/GlobalContext";
import TitleBar from "./TitleBar";

const Step2Form = () => {
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const [isSuspend, setIsSuspend] = useState(false);
  const router = useHistory();
  const { studentId, setStep, step, isKinder } = useContext(GlobalContext);
  useEffect(() => {
    if (step === -1) {
      router.push("/");
    }
    setStep(1);
  }, [router, setStep, step]);
  const onFinish = (values) => {
    console.log(values);
    values = { ...values, studentId };
    AdminService.newExperience(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Step-2 completed",
      });
      router.replace("/home/step3");
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const suspendChanged = (s) => {
    console.log(s);
    setIsSuspend(s === "Yes" ? true : false);
  };

  return (
    <div className="max-w-full mx-auto  lg:max-w-4xl bg-f5f5f5 p-2 lg:p-6 lg:border lg:border-gray-300">
      <TitleBar title="STUDENT’S SCHOOLING EXPERIENCE" />
      <div
        style={{
          maxWidth: "calc(100% - 16px)",
          overflowWrap: "anywhere",
          overflow: "hidden",
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          layout={lg ? "horizontal" : "inline"}
          {...GlobalVariables.formLayout}
        >
          {!isKinder && (
            <>
              <Form.Item
                label="Current School"
                style={{ marginBottom: 0 }}
                required
              >
                <Form.Item
                  name="currentSchool"
                  rules={[
                    { required: true, message: "This Field is required!" },
                  ]}
                  style={{ display: "inline-block", width: "calc(50% - 12px)" }}
                >
                  <Input placeholder="Please type current school name" />
                </Form.Item>

                <Form.Item
                  style={{ display: "inline-block", width: "calc(50% - 12px)" }}
                  name="currentGrade"
                >
                  <Select style={{ width: 180 }} placeholder="Grade">
                    {SchoolYears.map((c, index) => (
                      <Select.Option key={c} value={index.toString()}>
                        {c}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label="Previous school(s) attended"
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="preSchool1"
                  style={{ display: "inline-block", width: "calc(50% - 12px)" }}
                >
                  <Input placeholder="Please type previous school name" />
                </Form.Item>

                <Form.Item
                  name="preSchool1Grade"
                  style={{ display: "inline-block", width: "calc(50% - 12px)" }}
                >
                  <Select style={{ width: 180 }} placeholder="Grade">
                    {SchoolYears.map((c, index) => (
                      <Select.Option key={c} value={index.toString()}>
                        {c}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label="Previous school(s) attended"
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="preSchool2"
                  style={{ display: "inline-block", width: "calc(50% - 12px)" }}
                  help="if outside NSW, please indicate state/country and grades completed"
                >
                  <Input placeholder="Please type previous school name" />
                </Form.Item>

                <Form.Item
                  style={{ display: "inline-block", width: "calc(50% - 12px)" }}
                  name="preSchool2Grade"
                >
                  <Select style={{ width: 180 }} placeholder="Grade">
                    {SchoolYears.map((c, index) => (
                      <Select.Option key={c} value={index.toString()}>
                        {c}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Child’s achievements"
            rules={[{ required: true, message: "This Field is required!" }]}
            name="achievements"
            help={
              <span className="break-words">
                Please list your child’s achievements (i.e. social, behavioral,
                leadership, sport, art and academic areas)
              </span>
            }
          >
            <Input placeholder="Please type achievements" />
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 16,
            }}
            wrapperCol={{
              span: 8,
            }}
            label="Has your child ever been suspended, expelled or rejected by another school?"
            name="isSuspended"
            rules={[{ required: true, message: "This Field is required!" }]}
          >
            <Select
              placeholder="Please select"
              style={{ width: 180 }}
              onChange={suspendChanged}
            >
              <Select.Option value="No">No</Select.Option>
              <Select.Option value="Yes">Yes</Select.Option>
            </Select>
          </Form.Item>
          {isSuspend && (
            <Form.Item
              label="Please provide details"
              name="suspendDetails"
              rules={[{ required: true, message: "This Field is required!" }]}
            >
              <Input placeholder="Please type details" />
            </Form.Item>
          )}
          <Form.Item {...GlobalVariables.tailLayout}>
            <span className="font-semibold">
              How do you describe your child’s experience at his/her current
              school?
            </span>
          </Form.Item>
          <Form.Item label="Academically" name="academically">
            <Select style={{ width: 180 }} placeholder="Please Select">
              <Select.Option value="Very Good">Very Good</Select.Option>
              <Select.Option value="Good">Good</Select.Option>
              <Select.Option value="Average">Average</Select.Option>
              <Select.Option value="Needs Improvement">
                Needs Improvement
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Socially/Behaviorally" name="behaviorally">
            <Select style={{ width: 180 }} placeholder="Please Select">
              <Select.Option value="Very Good">Very Good</Select.Option>
              <Select.Option value="Good">Good</Select.Option>
              <Select.Option value="Average">Average</Select.Option>
              <Select.Option value="Needs Improvement">
                Needs Improvement
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...GlobalVariables.tailLayout}
            className="-mt-4"
            help={
              <span className="font-semibold text-gray-700 ">
                Please list any special circumstances of which school should be
                made aware.
                <br />
                <small>
                  (e.g. physical/intellectual/emotional/learning difficulties,
                  ADHD, gifts/talents. Please provide appropriate
                  reports/assessments of such circumstances -if applicable-.
                  Please also note that failure to disclose any special
                  circumstances may jeopardise your child’s enrolment)
                </small>
              </span>
            }
          ></Form.Item>
          <Form.Item
            name="circumstances"
            label="Special circumstances "
            rules={[{ required: true, message: "This Field is required!" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
            <Space>
              <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit">
                Next <ArrowRightOutlined />
              </Button>
              <Button onClick={() => router.push("/home/step3")}>
                <ArrowRightOutlined />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Step2Form;
