import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ClearOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Upload,
  Select,
  Divider,
  notification,
} from "antd";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import StudentDetails from "../admin/StudentDetails";
import { GlobalContext } from "../context/GlobalContext";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const UploadPass = ({ students }) => {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [student, setStudent] = useState(null);
  const [uform, setUform] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { setStep } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const router = useHistory();

  const uploadFile = (v) => {
    console.log(v);
    console.log(fileList);
    const files =
      fileList.length > 0
        ? fileList.map((f) => {
            return {
              type: f.type,
              size: f.size,
              name: f.name,
              url: f.response.url,
            };
          })
        : [];
    if (files.length === 0) {
      notification.error({ message: "Please upload file!" });
    }

    AdminService.newResource({
      description: v.description,
      studentId: v.studentId,
      files,
    }).then((data) => {
      notification.success({ message: "Success!", description: "Files sent!" });
      onReset();
      setIsSent(true);
      setUform(false);
      setStudent(null);
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChange = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const studentChange = (v) => {
    setStudent(v);
    console.log(v);
  };

  return (
    <div className="container">
      <Modal
        visible={isVisible}
        width={1200}
        onCancel={() => setIsVisible(false)}
        onOk={() => setIsVisible(false)}
        style={{ top: 20 }}
      >
        <StudentDetails sid={student} />
      </Modal>
      <div className="max-w-4xl mx-auto">
        {students.length === 0 && (
          <Space>
            <p>
              No enrolled application registered to this e-mail address were
              found.
            </p>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                setStep(0);
                router.push("/home/step1");
              }}
            >
              Start New Application
            </Button>
          </Space>
        )}
      </div>
      {students.length > 0 && (
        <div className="max-w-4xl bg-f5f5f5 p-4 border border-gray-300 mx-auto my-6">
          <br />
          <Form
            form={form}
            onFinish={uploadFile}
            onReset={onReset}
            {...GlobalVariables.formLayout}
          >
            <Form.Item
              label="Select enrolled student"
              name="studentId"
              rules={[{ required: true, message: "Please select student" }]}
            >
              <Select
                placeholder="Please select"
                value="sudent"
                onChange={studentChange}
              >
                {students.map((s) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {student && (
              <>
                <Form.Item {...GlobalVariables.tailLayout}>
                  <Space>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => setUform(!uform)}
                    >
                      Upload File(s) This Applicaton
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => setIsVisible(true)}
                    >
                      View Application Form
                    </Button>
                  </Space>
                </Form.Item>
                {uform && (
                  <>
                    <Form.Item {...GlobalVariables.tailLayout}>
                      <Divider orientation="left">
                        <span className="text-sky-500 font-semibold text-base">
                          Upload file(s) to your completed application(s)
                        </span>
                      </Divider>
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please type description about your upload(s)",
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Please type description about your upload(s)" />
                    </Form.Item>
                    <Form.Item label="Upload File(s)">
                      <>
                        <Upload
                          action={process.env.REACT_APP_API_BASEURL + "upload"}
                          headers={{
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          }}
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={handleChange}
                        >
                          {fileList.length >= 5 ? null : uploadButton}
                        </Upload>
                        <Modal
                          visible={previewVisible}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </>
                    </Form.Item>
                    <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
                      <Space>
                        <Button
                          type="default"
                          htmlType="reset"
                          icon={<ClearOutlined />}
                        >
                          Clear
                        </Button>
                        <Button
                          icon={<SaveOutlined />}
                          type="primary"
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      </Space>
                    </Form.Item>
                    {isSent && (
                      <Form.Item
                        {...GlobalVariables.tailLayout}
                        className="mt-6"
                      >
                        <Button
                          type="default"
                          onClick={() => router.push("/")}
                          icon={<ArrowRightOutlined />}
                        >
                          Return to Homepage
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};

export default UploadPass;
