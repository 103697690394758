import {
  ArrowRightOutlined,
  ClearOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Upload,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { Countries } from "src/resources/countries";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { languages } from "src/resources/languages";
import { religions } from "src/resources/religions";
import { SchoolYears } from "src/resources/Years";
import { GlobalContext } from "../context/GlobalContext";
import TitleBar from "./TitleBar";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const Step1Form = () => {
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const router = useHistory();
  const [isTemporary, setIsTemporary] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const { setStudentId, setStep, schools, setIsKinder, step } =
    useContext(GlobalContext);

  useEffect(() => {
    if (step === -1) {
      router.push("/");
    }
    setStep(0);
  }, [router, setStep, step]);
  const onFinish = (values) => {
    console.log(values);
    if (fileList.length === 0) {
      notification.error({
        message: "Error!",
        description: "Please upload student photo",
      });
      return;
    }
    const req = { ...values, photo: fileList[0].response.url };
    AdminService.newStudent(req).then((data) => {
      console.log(data.data);
      notification.success({
        message: "Success",
        description: "Step-1 completed",
      });
      setStudentId(data.data.id);
      router.replace("/home/step2");
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
  };
  const statusChanged = (s) => {
    console.log(s);
    setIsTemporary(s === "Temporary" ? true : false);
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const yearChanged = (v) => {
    console.log(v);
    v === "Kindergarten" ? setIsKinder(true) : setIsKinder(false);
  };
  return (
    <div className="max-w-full mx-auto lg:max-w-4xl bg-f5f5f5  p-2 lg:p-6 lg:border lg:border-gray-300 ">
      <TitleBar title="STUDENT DETAILS" />
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          paddingRight: "10px",
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          layout={lg ? "horizontal" : "inline"}
          {...GlobalVariables.formLayout}
          initialValues={{ cob: "1101" }}
        >
          <Form.Item
            label="Family Name"
            name="familyName"
            rules={[{ required: true, message: "Family name is required!" }]}
          >
            <Input placeholder="Please type family name" />
          </Form.Item>
          <Form.Item
            label="Given Name"
            name="givenName"
            rules={[{ required: true, message: "Given name is required!" }]}
          >
            <Input placeholder="Please type given name" />
          </Form.Item>
          <Form.Item label="Middle Name" name="middleName">
            <Input placeholder="Please type middle name(s)" />
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name="dob"
            rules={[{ required: true, message: "DOB is required!" }]}
          >
            <DatePicker format="DD-MM-YYYY" placeholder="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Gender is required!" }]}
          >
            <Select placeholder="Please select" style={{ width: 180 }}>
              <Select.Option value="M">Male</Select.Option>
              <Select.Option value="F">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Country Of Birth"
            name="cob"
            rules={[{ required: true, message: "COB is required!" }]}
          >
            <Select
              showSearch
              style={{ width: 180 }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Countries.map((c) => (
                <Select.Option key={c.code} value={c.code}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="A Recent Photo" required>
            <div className="clearfix">
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChangeImage}
                onPreview={handlePreview}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
            <small>Minimum 35mm x 45mm</small>
          </Form.Item>
          <Form.Item label="Ethnic Background" name="ethnic">
            <Input placeholder="Please type ethnic bacground" />
          </Form.Item>
          <Form.Item
            label="Religion"
            name="religion"
            rules={[{ required: true, message: "Religion is required!" }]}
          >
            <Select
              showSearch
              style={{ width: 180 }}
              placeholder="Pleace Select"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {religions
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c.code} value={c.code}>
                    {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Language(s) Spoken at Home"
            name="languages"
            rules={[{ required: true, message: "Languges are required" }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pleace Select Languages"
              mode="multiple"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {languages
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((c) => (
                  <Select.Option key={c.code} value={c.code}>
                    {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {/* <Form.Item
            label="Campus Applied"
            name="campus"
            rules={[{ required: true, message: "Campus is required!" }]}
          >
            <Select style={{ width: 180 }} placeholder="Please Select">
              <Select.Option value="Prestons">
                Prestons Campus (K-12)
              </Select.Option>
              <Select.Option value="Auburn">Auburn Campus (K-6)</Select.Option>
              <Select.Option value="Illawarra">
                Illawarra Campus (K-10)
              </Select.Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Campus Applied"
            name="schoolId"
            rules={[{ required: true, message: "School is required!" }]}
          >
            <Select style={{ width: 180 }} placeholder="Please Select">
              {schools.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Applying to enter Year">
            <Form.Item
              name="toYear"
              rules={[{ required: true, message: "Year is required!" }]}
              style={{ display: "inline-block", width: "180px" }}
            >
              <Select
                style={{ width: 180 }}
                placeholder="Please Select"
                onChange={yearChanged}
              >
                {SchoolYears.map((c, index) => (
                  <Select.Option key={c} value={index.toString()}>
                    {c}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="startDate"
              rules={[{ required: true, message: "This date is required!" }]}
              style={{ display: "inline-block" }}
            >
              <DatePicker placeholder="Start Date" format="DD-MM-YYYY" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Indigenous Status"
            name="indigenous"
            rules={[{ required: true, message: "Indigenous is required!" }]}
          >
            <Select style={{ width: 180 }} placeholder="Please Select">
              <Select.Option value="None">None</Select.Option>
              <Select.Option value="Aboriginal">Aboriginal</Select.Option>
              <Select.Option value="Torres Strait Islander">
                Torres Strait Islander
              </Select.Option>
              <Select.Option value="Both Ab. Torres S.I.">
                Both Ab. Torres S.I.
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Citizenship / Visa Status"
            name="status"
            rules={[{ required: true, message: "Status is required!" }]}
          >
            <Select
              style={{ width: 180 }}
              placeholder="Please Select"
              onChange={statusChanged}
            >
              <Select.Option value="Citizen">Australian Citizen</Select.Option>
              <Select.Option value="Permanent">
                Permanent Resident
              </Select.Option>
              <Select.Option value="Temporary">
                Temporary Resident
              </Select.Option>
            </Select>
          </Form.Item>
          {isTemporary && (
            <>
              <Form.Item
                label="Passport No"
                name="passportNo"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Please type Passport no" />
              </Form.Item>
              <Form.Item
                label="Visa Subclass"
                name="subclass"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Please type subclass" />
              </Form.Item>
              <Form.Item
                label="Visa Expires"
                name="expires"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker format="DD-MM-YYYY" placeholder="DD-MM-YYYY" />
              </Form.Item>
              <Form.Item {...GlobalVariables.tailLayout}>
                <span className="font-semibold">
                  Please provide copy of residency documents. Please also note
                  that any residency status change must be immediately advised.
                </span>
              </Form.Item>
            </>
          )}
          <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
            <Space>
              <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit">
                Next <ArrowRightOutlined />
              </Button>
              <Button onClick={() => router.replace("/home/step2")}>
                <ArrowRightOutlined />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Step1Form;
