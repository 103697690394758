import { notification } from "antd";
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { GlobalContext } from "src/components/context/GlobalContext";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { role } = useContext(GlobalContext);

  if (role !== "Admin") {
    notification.destroy();
    notification.warn({
      message: "Unauthorized transaction!",
      description: "Please login again",
    });
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        role === "Admin" ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login/loginpage",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const SchoolAdminRoute = ({ component: Component, ...rest }) => {
  const { role } = useContext(GlobalContext);
  console.log("role", role !== "SchoolAdmin");
  if (["Admin", "SchoolAdmin"].findIndex((a) => a === role) === -1) {
    console.log("role2", role);

    notification.destroy();
    notification.warn({
      message: "Unauthorized transaction!",
      description: "Please login againnnnn",
    });
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        role === "Admin" || role === "SchoolAdmin" ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login/loginpage",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export { AdminRoute, SchoolAdminRoute };
