import {
  ArrowRightOutlined,
  ClearOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Input, Modal, notification, Space } from "antd";
import React, { useState, useContext } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalContext } from "../context/GlobalContext";

const Contacts = () => {
  const [isModal, setIsModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const { schools } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const gridStyle = {
    width: "33.33%",
    textAlign: "center",
    padding: 15,
  };
  const onFinish = (v) => {
    console.log(v);
    v = { ...v, schoolId: activeId };
    AdminService.newContactMessage(v).then((data) => {
      console.log(data.data);
      notification.success({ message: "Message sent!" });
      onReset();
      setIsModal(false);
      setActiveId(null);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const goMessage = (id) => {
    setActiveId(id);
    setIsModal(true);
    console.log(id);
  };
  return (
    <div className="max-w-4xl mx-auto mb-12">
      <Modal visible={isModal} footer={null} onCancel={() => setIsModal(false)}>
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          layout="vertical"
        >
          <Form.Item
            label="Full name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input placeholder="Please type your name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Invalid email format" },
            ]}
          >
            <Input placeholder="Please type your email" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input placeholder="Please type your phone" />
          </Form.Item>
          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true, message: "Message is required" }]}
          >
            <Input.TextArea placeholder="Please type your message" />
          </Form.Item>
          <Form.Item className="mt-6">
            <Space>
              <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                icon={<ArrowRightOutlined />}
              >
                Send
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Card
        className="border border-gray-300 shadow-sm"
        title={
          <>
            <span className="text-sky-500 font-semibold text-base">
              Enrolment Application Support
            </span>{" "}
            |{" "}
            <span className="text-gray-500 font-semibold text-sm">
              Please contact us if you require any assistance:
            </span>
          </>
        }
      >
        {schools.map((s) => (
          <Card.Grid style={gridStyle} key={s.id}>
            <p className="text-sky-500 font-semibold text-base">{s.name}</p>
            <p className="text-base">{s.phone}</p>
            <p>{s.email}</p>
            <Button
              className="mt-2"
              size="small"
              type="link"
              icon={<MailOutlined />}
              onClick={() => goMessage(s.id)}
            >
              Send Message
            </Button>
          </Card.Grid>
        ))}
      </Card>
    </div>
  );
};

export default Contacts;
