export const languages = [
  { code: "0000", name: "Unknown" },
  { code: "0001", name: "Non Verbal" },
  { code: "0002", name: "Not Stated" },
  { code: "1101", name: "Gaelic (Scotland)" },
  { code: "1102", name: "Irish" },
  { code: "1103", name: "Welsh" },
  { code: "1199", name: "Celtic, nec (Breton, Cornish," },
  { code: "1201", name: "English" },
  { code: "1301", name: "German" },
  { code: "1302", name: "Luxembourgish" },
  { code: "1303", name: "Yiddish" },
  { code: "1401", name: "Netherlandic" },
  { code: "1402", name: "Frisian" },
  { code: "1403", name: "Afrikaans" },
  { code: "1501", name: "Danish" },
  { code: "1502", name: "Icelandic" },
  { code: "1503", name: "Norwegian" },
  { code: "1504", name: "Swedish" },
  { code: "1599", name: "Scandinavian, nec (Faeroese)" },
  { code: "2101", name: "French" },
  { code: "2201", name: "Greek" },
  { code: "2300", name: "Hispanic" },
  { code: "2301", name: "Catalan" },
  { code: "2302", name: "Portuguese" },
  { code: "2303", name: "Spanish" },
  { code: "2399", name: "Iberian Romance(Crioulo,Galici" },
  { code: "2401", name: "Italian" },
  { code: "2501", name: "Maltese" },
  { code: "2901", name: "Basque" },
  { code: "2902", name: "Latin" },
  { code: "2999", name: "Other SthEurope(Friulian,Ladin" },
  { code: "3000", name: "Eastern European Languages" },
  { code: "3101", name: "Latvian" },
  { code: "3102", name: "Lithuanian" },
  { code: "3201", name: "Estonian" },
  { code: "3202", name: "Finnish" },
  { code: "3299", name: "Finnic nec (Karelian, Lapp, Lu" },
  { code: "3301", name: "Hungarian" },
  { code: "3401", name: "Belorussian" },
  { code: "3402", name: "Russian" },
  { code: "3403", name: "Ukrainian" },
  { code: "3500", name: "South Slavic" },
  { code: "3501", name: "Bosnian" },
  { code: "3502", name: "Bulgarian" },
  { code: "3503", name: "Croatian" },
  { code: "3504", name: "Macedonian" },
  { code: "3505", name: "Serbian" },
  { code: "3506", name: "Slovene" },
  { code: "3507", name: "Serbo-Croatian/Yugoslavian, so described" },
  { code: "3600", name: "West Slavic" },
  { code: "3601", name: "Czech" },
  { code: "3602", name: "Polish" },
  { code: "3603", name: "Slovak" },
  { code: "3901", name: "Albanian" },
  { code: "3902", name: "Armenian" },
  { code: "3903", name: "Aromunian (Macedo-Romanian)" },
  { code: "3904", name: "Romanian" },
  { code: "3999", name: "Other EastEuro(Georgian, Ingus" },
  { code: "4000", name: "Southwest and Central Asian Languages, nfd" },
  { code: "4100", name: "Iranic" },
  { code: "4101", name: "Kurdish" },
  { code: "4102", name: "Pashto" },
  { code: "4103", name: "Persian" },
  { code: "4104", name: "Balochi" },
  { code: "4105", name: "Dari" },
  { code: "4106", name: "Persian (excluding Dari)" },
  { code: "4199", name: "Iranic, nec (Balochi, Ossetic," },
  { code: "4200", name: "Middle Eastern Semitic Languages, nfd" },
  { code: "4201", name: "Amharic" },
  { code: "4202", name: "Arabic (including Lebanese)" },
  { code: "4203", name: "Assyrian (including Aramaic)" },
  { code: "4204", name: "Hebrew" },
  { code: "4205", name: "Tigrinya" },
  { code: "4299", name: "MiddleEast,NthAfrican(Kabyle,R" },
  { code: "4300", name: "Turkic, nfd" },
  { code: "4301", name: "Turkish" },
  { code: "4304", name: "Turkmen" },
  { code: "4305", name: "Uygur" },
  { code: "4399", name: "Turkish,Central Asia(Azeri,Mon" },
  { code: "4901", name: "Armenian" },
  { code: "5000", name: "Southern Asian (Indian, Sri La" },
  { code: "5101", name: "Kannada" },
  { code: "5102", name: "Malayalam" },
  { code: "5103", name: "Tamil" },
  { code: "5104", name: "Telugu" },
  { code: "5199", name: "Dravidian, nec (Brahui, Malto," },
  { code: "5200", name: "Indo-Aryan, nfd" },
  { code: "5201", name: "Bengali" },
  { code: "5202", name: "Gujarati" },
  { code: "5203", name: "Hindi" },
  { code: "5204", name: "Konkani" },
  { code: "5205", name: "Marathi" },
  { code: "5206", name: "Nepali" },
  { code: "5207", name: "Punjabi" },
  { code: "5208", name: "Sindhi" },
  { code: "5211", name: "Sinhalese" },
  { code: "5212", name: "Urdu" },
  { code: "5215", name: "Kashmiri" },
  { code: "5299", name: "Indo-Aryan(Assamese,Kashmiri,R" },
  { code: "5999", name: "Sthn Asian (Balti,Burushaski,N" },
  { code: "6000", name: "Southeast Asian Languages, nfd" },
  { code: "6100", name: "Burman" },
  { code: "6101", name: "Burmese" },
  { code: "6199", name: "Burman, nec (incl. Lisu, Pho," },
  { code: "6201", name: "Hmong" },
  { code: "6299", name: "Hmong-Mien Languages, nec (Mie" },
  { code: "6301", name: "Khmer" },
  { code: "6302", name: "Vietnamese" },
  { code: "6399", name: "Mon-Khmer(incl. Khasi, Khmu, M" },
  { code: "6400", name: "Tai" },
  { code: "6401", name: "Lao" },
  { code: "6402", name: "Thai" },
  { code: "6499", name: "Tai, nec (incl. Buyi, Jui, Tho" },
  { code: "6501", name: "Bisaya" },
  { code: "6502", name: "Cebuano" },
  { code: "6503", name: "Ilokano" },
  { code: "6504", name: "Indonesian" },
  { code: "6505", name: "Malay" },
  { code: "6506", name: "Tagalog (Filipino)" },
  { code: "6507", name: "Tetum" },
  { code: "6508", name: "Timorese" },
  { code: "6511", name: "Tagalog" },
  { code: "6599", name: "Austronesian(Balinese,Bikol,Ka" },
  { code: "6999", name: "Other Southeast Asian (Chavaca" },
  { code: "7100", name: "Chinese" },
  { code: "7101", name: "Cantonese" },
  { code: "7102", name: "Hakka" },
  { code: "7103", name: "Hokkien" },
  { code: "7104", name: "Mandarin" },
  { code: "7105", name: "Teochew" },
  { code: "7106", name: "Wu" },
  { code: "7199", name: "Chinese, nec (Chang Chow, Huna" },
  { code: "7201", name: "Japanese" },
  { code: "7301", name: "Korean" },
  { code: "7900", name: "Other Eastern Asian Languages, nfd" },
  { code: "7999", name: "Eastern Asian (Ainu, Bhotia, T" },
  { code: "8000", name: "Aboriginal" },
  { code: "8101", name: "Anindilyakwa" },
  { code: "8102", name: "Burarra" },
  { code: "8103", name: "Dhaangu" },
  { code: "8104", name: "Dhay'yi" },
  { code: "8105", name: "Dhuwal-Dhuwala" },
  { code: "8106", name: "Djinang" },
  { code: "8107", name: "Karrwa (Garawa, Garrwa)" },
  { code: "8108", name: "Kunwinjku (Gunwinggu)" },
  { code: "8111", name: "Maung" },
  { code: "8112", name: "Murrinh-Patha" },
  { code: "8113", name: "Ngangkikurungurr" },
  { code: "8114", name: "Nunggubuyu" },
  { code: "8115", name: "Rembarrnga" },
  { code: "8116", name: "Ritharrngu" },
  { code: "8117", name: "Tiwi" },
  { code: "8118", name: "Yanyuwa (Anula)" },
  { code: "8199", name: "Northern Aboriginal" },
  { code: "8201", name: "Alyawarr (Alyawarra)" },
  { code: "8202", name: "Anmatyerr (Anmatyirra)" },
  { code: "8203", name: "Arrernte (Aranda)" },
  { code: "8204", name: "Bardi" },
  { code: "8205", name: "Bunuba (Bunaba)" },
  { code: "8206", name: "Jaru (Djaru)" },
  { code: "8207", name: "Kija (Gidya)" },
  { code: "8208", name: "Kuurinji (Gurindji)" },
  { code: "8211", name: "Kukatha (Gugada, Kokatha)" },
  { code: "8212", name: "Kukatja (Gugaja)" },
  { code: "8213", name: "Miriwoong" },
  { code: "8214", name: "Mutpurra (Mudburra)" },
  { code: "8215", name: "Ngaatjatjara" },
  { code: "8216", name: "Nyangumarta" },
  { code: "8217", name: "Pintupi" },
  { code: "8218", name: "Pitjantjatjara" },
  { code: "8221", name: "Walmajarri (Walmadjari)" },
  { code: "8222", name: "Warumungu (Warumunga)" },
  { code: "8223", name: "Warlpiri" },
  { code: "8224", name: "Yulparija" },
  { code: "8225", name: "Yankunytjatjara" },
  { code: "8243", name: "Gupapuyngu" },
  { code: "8299", name: "Central Aboriginal" },
  { code: "8301", name: "Gugu Yalanji" },
  { code: "8302", name: "Guugu Yimidhirr" },
  { code: "8303", name: "Kuuku-Ya'u" },
  { code: "8304", name: "Wik-Mungkan" },
  { code: "8399", name: "Cape York Peninsula Aboriginal" },
  { code: "8400", name: "Torres Strait Islander" },
  { code: "8401", name: "Kalaw Lagaw Ya (Kalaw Kawa Ya)" },
  { code: "8402", name: "Meryam Mir" },
  { code: "8501", name: "Ngarluma" },
  { code: "8502", name: "Nyungar (Noongar)" },
  { code: "8503", name: "Yindjibarndi" },
  { code: "8599", name: "West Coast Aboriginal" },
  { code: "8601", name: "Adnymathanha (Yura Ngawarla)" },
  { code: "8602", name: "Arabana (Arabuna)" },
  { code: "8699", name: "Eastern Aboriginal" },
  { code: "8701", name: "Kriol" },
  { code: "8702", name: "Torres Strait Creole (Broken)" },
  { code: "9000", name: "Other Languages, nfd" },
  { code: "9101", name: "American Languages" },
  { code: "9200", name: "African Languages (Excl  North" },
  { code: "9201", name: "Acholi" },
  { code: "9202", name: "Afrikaans" },
  { code: "9203", name: "Akan" },
  { code: "9204", name: "Asante" },
  { code: "9205", name: "Mauritian Creole" },
  { code: "9206", name: "Oromo" },
  { code: "9207", name: "Shona" },
  { code: "9208", name: "Somali" },
  { code: "9211", name: "Swahili" },
  { code: "9212", name: "Yoruba" },
  { code: "9213", name: "Zulu" },
  { code: "9225", name: "Krio" },
  { code: "9228", name: "Ndebele" },
  { code: "9299", name: "African (excl Nth)(Bemba,Fante" },
  { code: "9301", name: "Fijian" },
  { code: "9302", name: "Gilbertese" },
  { code: "9303", name: "Maori (Cook Island)" },
  { code: "9304", name: "Maori (New Zealand)" },
  { code: "9305", name: "Motu" },
  { code: "9306", name: "Nauruan" },
  { code: "9307", name: "Niue" },
  { code: "9308", name: "Samoan" },
  { code: "9311", name: "Tongan" },
  { code: "9399", name: "Austronesian (Hawaiian,Rotuman" },
  { code: "9400", name: "Creole" },
  { code: "9401", name: "Tok Pisin" },
  { code: "9499", name: "Pidgins&Creoles(Bislama,Pitcai" },
  { code: "9501", name: "Papuan Languages" },
  { code: "9601", name: "Invented Languages" },
  { code: "9700", name: "Sign Language" },
  { code: "9701", name: "Auslan" },
  { code: "9702", name: "Makaton" },
  { code: "9799", name: "Sign Languages(American,Amesla" },
];
