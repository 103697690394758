import React from "react";

const TitleBar = ({ title }) => {
  return (
    <div className="bg-sky-500 border border-sky-700 shadow mb-4 text-white p-2 text-xl font-semibold">
      {title}
    </div>
  );
};

export default TitleBar;
