import { Divider, Radio } from "antd";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import CampusStudents from "./CampusStudents";

const Students = () => {
  const { schools } = useContext(GlobalContext);
  const { selectedCampus, setSelectedCampus, permits } =
    useContext(GlobalContext);
  const campusChanged = (v) => {
    console.log(v.target.value);
    setSelectedCampus(v.target.value);
  };
  const getPermit = (id) => {
    return permits.findIndex((p) => p === id) === -1 ? true : false;
  };
  return (
    <div className="max-w-7xl mx-auto">
      <Divider orientation="left">
        <span className="text-sky-500 text-base font-semibold">
          Please select campus
        </span>
      </Divider>
      <Radio.Group
        value={selectedCampus}
        buttonStyle="solid"
        style={{ marginBottom: 16 }}
        onChange={campusChanged}
      >
        {schools.map((s) => (
          <Radio.Button key={s.id} value={s.id} disabled={getPermit(s.id)}>
            {s.name}
          </Radio.Button>
        ))}
      </Radio.Group>
      {selectedCampus && <CampusStudents campus={selectedCampus} />}
    </div>
  );
};

export default Students;
