import {
  ArrowRightOutlined,
  ClearOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Schools = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [schools, setSchools] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getSchools().then((data) => {
        console.log(data.data);
        setSchools(data.data);
      });
    }
  }, [initialRender]);

  const goDelete = (id) => {
    AdminService.deleteSchool(id).then((data) => {
      notification.success({ message: "School Deleted!" });
      setInitialRender(true);
    });
  };
  const cancel = () => {
    notification.info({ message: "Cancelled!" });
  };
  const columns = [
    {
      title: "#ID",
      dataIndex: "id",
    },
    {
      title: "School Name",
      dataIndex: "name",
    },
    {
      title: "School Phone",
      dataIndex: "phone",
    },
    {
      title: "School Email",
      dataIndex: "email",
    },
    {
      title: "Act.",
      render: (item) => (
        <Tooltip title="Delete School" placement="bottom">
          <Popconfirm
            title={
              <span className="text-red-600">
                Are you sure to delete this School?
                <br /> All students data will be deleted
              </span>
            }
            onConfirm={() => goDelete(item.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            {item.role !== "Admin" && (
              <Button type="primary" danger icon={<DeleteOutlined />} />
            )}
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  const onFinish = (v) => {
    console.log(v);
    AdminService.newSchool(v).then((data) => {
      notification.success({ message: "Scholl Added!" });
      setInitialRender(true);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="border border-gray-300 bg-f5f5f5 p-6">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Divider orientation="left">Saved Schools</Divider>
          <Table
            size="small"
            bordered
            rowKey="id"
            pagination={false}
            dataSource={schools}
            columns={columns}
            loading={schools === null}
          />
        </Col>
        <Col span={12}>
          <Divider orientation="left">Add New School</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            {...GlobalVariables.formLayout}
          >
            <Form.Item
              label="School Name"
              name="name"
              rules={[{ required: true, message: "School name is required!" }]}
            >
              <Input placeholder="Please type school name" />
            </Form.Item>
            <Form.Item
              label="School Phone"
              name="phone"
              rules={[{ required: true, message: "School phone is required!" }]}
            >
              <Input placeholder="Please type school phone" />
            </Form.Item>
            <Form.Item
              label="School Email"
              name="email"
              rules={[{ required: true, message: "School email is required!" }]}
            >
              <Input placeholder="Please type school email" />
            </Form.Item>
            <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
              <Space>
                <Button
                  type="default"
                  htmlType="reset"
                  icon={<ClearOutlined />}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Schools;
