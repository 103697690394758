export const religions = [
  { code: "ANG", name: "Anglican" },
  { code: "BAPTI", name: "Baptist" },
  { code: "BUD", name: "Budism" },
  { code: "CAT", name: "Catholic" },
  { code: "CHRS", name: "Christian" },
  { code: "HIND", name: "Hindu" },
  { code: "ISL", name: "Islam" },
  { code: "MINDE", name: "Mindean" },
  { code: "NONE", name: "No Religious Belief" },
  { code: "ORTH", name: "Orthodox" },
  { code: "SABIA", name: "Sabian Mandaean" },
  { code: "SKH", name: "Sikh" },
  { code: "UNKN", name: "Unknown" },
  { code: "UNSPE", name: "Unspecified" },
  { code: "URDU", name: "Urdu" },
  { code: "OTHER", name: "Other" },
];
