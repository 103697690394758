import {
  DownloadOutlined,
  FileDoneOutlined,
  FilePdfTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  notification,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const CampusStudents = ({ campus }) => {
  const [initialRender, setInitialRender] = useState(true);
  const [students, setStudents] = useState(null);
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const searchInput = useRef();
  const router = useHistory();
  useEffect(() => {
    AdminService.getCampusStudents(campus).then((data) => {
      console.log(data.data);
      setStudents(data.data);
    });
  }, [campus, initialRender]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: "Given Name",
      dataIndex: "givenName",
      ...getColumnSearchProps("givenName"),
      sorter: (a, b) => GlobalVariables.compare(a.givenName, b.givenName),
    },
    {
      title: "Middle Name",
      dataIndex: "middleName",
      ...getColumnSearchProps("middleName"),
      sorter: (a, b) => GlobalVariables.compare(a.middleName, b.middleName),
    },
    {
      title: "Family Name",
      dataIndex: "familyName",
      ...getColumnSearchProps("familyName"),
      sorter: (a, b) => GlobalVariables.compare(a.familyName, b.familyName),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      filters: [
        { text: "M", value: "M" },
        { text: "F", value: "F" },
      ],
      onFilter: (value, record) => record.gender.includes(value),
    },
    {
      title: "To Year",
      dataIndex: "toYear",
      filters: [
        { text: "0", value: "0" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
      ],
      onFilter: (value, record) => record.toYear.includes(value),
    },
    {
      title: "Enrol Year",
      dataIndex: "startDate",
      render: (startDate) => moment(startDate).format("YYYY"),
      filters: [
        { text: "2021", value: "2021" },
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
        { text: "2025", value: "2025" },
      ],
      onFilter: (value, record) =>
        moment(record.startDate).format("YYYY").toString() === value,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (startDate) => moment(startDate).format("DD-MM-YY"),
    },

    {
      title: "Submit Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY HH:mm"),
    },

    {
      title: "R.Status",
      dataIndex: "status",
      filters: [
        { text: "Citizen", value: "Citizen" },
        { text: "Permanent", value: "Permanent" },
        { text: "Temporary", value: "Temporary" },
      ],
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Actions",
      render: (item) => (
        <Space size="small">
          <Tooltip title="Details">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => router.push(`/admin/students/${item.id}/details`)}
            />
          </Tooltip>
          <Tooltip title="Download XML">
            <Button
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => downloadXml(item.id)}
            >
              XML
            </Button>
          </Tooltip>
          {item.resources.length > 0 && (
            <Tooltip title="Documents">
              <Button
                type="primary"
                icon={<FileDoneOutlined />}
                onClick={() =>
                  router.push(`/admin/students/${item.id}/documents`)
                }
              >
                Docs.({item.resources.length})
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Download Invoice">
            <FilePdfTwoTone
              style={{ fontSize: "32px" }}
              twoToneColor="red"
              onClick={() => downloadInvoice(item.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Completed",
      dataIndex: "isCompleted",
      render: (isCompleted, item, index) => (
        <Switch
          defaultChecked={isCompleted}
          onChange={(a) => completeChanged(a, item.id)}
        />
      ),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) =>
        record.isCompleted.toString().includes(value.toString()),
    },
  ];
  const completeChanged = (deger, id) => {
    console.log("id:", id);
    AdminService.changeCompleted(id, deger).then((data) => {
      notification.success({
        message: "Success",
        description: "Saved successfully.",
      });
      setInitialRender(!initialRender);
    });
  };
  const downloadInvoice = (id) => {
    AdminService.getInvoicePdf(id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  const downloadXml = (id) => {
    AdminService.getStudentXml(id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Student-${id}.xml`);
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="max-w-7xl mx-auto">
      <Table
        rowKey="id"
        columns={columns}
        dataSource={students}
        bordered
        loading={students === null}
        size="small"
        pagination={{ defaultPageSize: 50 }}
      />
    </div>
  );
};

export default CampusStudents;
