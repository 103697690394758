import { Button, Form, Input, notification } from "antd";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { GlobalContext } from "../context/GlobalContext";
import Contacts from "./Contacts";
import UploadPass from "./UploadPass";

const SecureBegin = () => {
  const [email, setEmail] = useState(null);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [students, setStudents] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setContact, setStep, yol } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const router = useHistory();
  const onFinish = (values) => {
    setLoading(true);
    email === null
      ? AdminService.getLoginCode(values.email)
          .then((data) => {
            setEmail(values.email);
            setEmailDisabled(true);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          })
      : AdminService.checkPassCode(values)
          .then((data) => {
            console.log(data.data);
            localStorage.setItem("token", data.data.restoken);
            setContact(values.email);
            setLoading(false);

            if (data.data.students.length === 0) {
              if (!yol) {
                notification.error({
                  message: "Error!",
                  description:
                    "No  application registered to this e-mail address were found.",
                });
                setEmail(null);
                setEmailDisabled(false);
                onReset();
                return;
              }
              setStep(0);
              router.push("/home/step1");
            } else {
              if (yol) {
                setStep(0);
                router.push("/home/step1");
              } else {
                setStudents(data.data.students);
              }
            }
          })
          .catch(() => {
            setLoading(false);
          });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="py-4">
      <img
        className="relative mx-auto"
        width={150}
        src={require("../../assets/images/logo.png").default}
        alt=""
      />
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={284}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-sky-500 ">
            APPLICATION FOR ENROLMENT
          </h2>
        </div>
      </div>
      {students === null && (
        <div className="bg-white py-6 sm:py-12">
          <div className="relative sm:py-16">
            <div aria-hidden="true" className="hidden sm:block">
              <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-100 rounded-r-3xl" />
              <svg
                className="absolute top-8 left-1/2 -ml-3"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                />
              </svg>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-5xl lg:px-8">
              <div className="relative rounded-2xl px-6 py-6 bg-sky-600 overflow-hidden shadow-xl sm:px-12 sm:py-10">
                <div
                  aria-hidden="true"
                  className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 1463 360"
                  >
                    <path
                      className="text-sky-500 text-opacity-40"
                      fill="currentColor"
                      d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                    />
                    <path
                      className="text-sky-700 text-opacity-40"
                      fill="currentColor"
                      d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                    />
                  </svg>
                </div>
                <div className="relative">
                  <div className="sm:text-center">
                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                      Get One Time Pass Code
                    </h2>
                    <p className="my-6 mx-auto max-w-2xl text-base text-gray-100">
                      This application form allows families to enrol online and
                      pay via credit card.
                      <br />
                      A non-refundable fee of $100 will be charged per
                      registration.
                      <br />
                      Please enter your email address. We'll send you an email
                      with an access code to securely begin your application.
                    </p>
                  </div>
                  <div className=" flex justify-center">
                    <Form
                      style={{ width: "100%", maxWidth: "400px" }}
                      onFinish={onFinish}
                      onReset={onReset}
                      form={form}
                    >
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: "Please type your email" },
                          { type: "email", message: "Invalid email format" },
                        ]}
                      >
                        <Input
                          placeholder="Type your email"
                          size="large"
                          disabled={emailDisabled}
                        />
                      </Form.Item>
                      {!emailDisabled && (
                        <Form.Item>
                          <Button
                            loading={loading}
                            htmlType="submit"
                            size="large"
                            type="default"
                            block
                          >
                            Get Access Code
                          </Button>
                        </Form.Item>
                      )}
                      {emailDisabled && (
                        <>
                          <span className="text-white text-center">
                            A code has been sent to your e-mail. If the code is
                            not received, check the junk/spam folders.
                          </span>
                          <Form.Item
                            name="code"
                            rules={[
                              {
                                required: true,
                                message: "Please type 6 digit access code",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Type your one pass code"
                              size="large"
                              className="block w-full"
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              loading={loading}
                              htmlType="submit"
                              size="large"
                              block
                            >
                              {yol
                                ? "Start Application"
                                : "Get My Application(s)"}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {students !== null && !yol && <UploadPass students={students} />}
      <Contacts />
    </div>
  );
};

export default SecureBegin;
