export const Countries = [
  { code: "0000", name: "Inadequately Described" },
  { code: "0001", name: "At Sea" },
  { code: "0003", name: "Not Stated" },
  { code: "0911", name: "Europe" },
  { code: "0912", name: "Former USSR" },
  { code: "0913", name: "Former Yugoslavia" },
  { code: "0914", name: "Former Czechoslovakia" },
  { code: "0916", name: "East Asia" },
  { code: "0917", name: "Asia" },
  { code: "0918", name: "Africa" },
  { code: "1000", name: "Oceania & Antarctica" },
  { code: "1100", name: "Australia (incl. External Territories)" },
  { code: "1101", name: "Australia" },
  { code: "1102", name: "Norfolk Island" },
  { code: "1199", name: "Australian External Territories, nec" },
  { code: "1201", name: "New Zealand" },
  { code: "1300", name: "Melanesia" },
  { code: "1301", name: "New Caledonia" },
  { code: "1302", name: "Papua New Guinea" },
  { code: "1303", name: "Solomon Islands" },
  { code: "1304", name: "Vanuatu" },
  { code: "1400", name: "Micronesia" },
  { code: "1401", name: "Guam" },
  { code: "1402", name: "Kiribati" },
  { code: "1403", name: "Marshall Islands" },
  { code: "1404", name: "Micronesia, Federated States of" },
  { code: "1405", name: "Nauru" },
  { code: "1406", name: "Northern Mariana Islands" },
  { code: "1407", name: "Palau" },
  { code: "1500", name: "Polynesia (excl. Hawaii)" },
  { code: "1501", name: "Cook Islands" },
  { code: "1502", name: "Fiji" },
  { code: "1503", name: "French Polynesia" },
  { code: "1504", name: "Niue" },
  { code: "1505", name: "Samoa" },
  { code: "1506", name: "Samoa, American" },
  { code: "1507", name: "Tokelau" },
  { code: "1508", name: "Tonga" },
  { code: "1511", name: "Tuvalu" },
  { code: "1512", name: "Wallis & Futuna" },
  { code: "1599", name: "Polynesia (excl. Hawaii), nec" },
  { code: "1600", name: "Antarctica" },
  { code: "1601", name: "Adelie Land (France)" },
  { code: "1602", name: "Argentinian Antarctic Territory" },
  { code: "1603", name: "Australian Antarctic Territory" },
  { code: "1604", name: "British Antarctic Territory" },
  { code: "1605", name: "Chilean Antarctic Territory" },
  { code: "1606", name: "Queen Maud Land (Norway)" },
  { code: "1607", name: "Ross Dependency (New Zealand)" },
  { code: "2000", name: "North-West Europe" },
  { code: "2100", name: "United Kingdom" },
  { code: "2101", name: "Channel Islands" },
  { code: "2102", name: "England" },
  { code: "2103", name: "Isle of Man" },
  { code: "2104", name: "Northern Ireland" },
  { code: "2105", name: "Scotland" },
  { code: "2106", name: "Wales" },
  { code: "2201", name: "Ireland" },
  { code: "2300", name: "Western Europe" },
  { code: "2301", name: "Austria" },
  { code: "2302", name: "Belgium" },
  { code: "2303", name: "France" },
  { code: "2304", name: "Germany" },
  { code: "2305", name: "Liechtenstein" },
  { code: "2306", name: "Luxembourg" },
  { code: "2307", name: "Monaco" },
  { code: "2308", name: "Netherlands" },
  { code: "2311", name: "Switzerland" },
  { code: "2400", name: "Northern Europe" },
  { code: "2401", name: "Denmark" },
  { code: "2402", name: "Faeroe Islands" },
  { code: "2403", name: "Finland" },
  { code: "2404", name: "Greenland" },
  { code: "2405", name: "Iceland" },
  { code: "2406", name: "Norway" },
  { code: "2407", name: "Sweden" },
  { code: "3000", name: "Southern & Eastern Europe" },
  { code: "3100", name: "Southern Europe" },
  { code: "3101", name: "Andorra" },
  { code: "3102", name: "Gibraltar" },
  { code: "3103", name: "Holy See" },
  { code: "3104", name: "Italy" },
  { code: "3105", name: "Malta" },
  { code: "3106", name: "Portugal" },
  { code: "3107", name: "San Marino" },
  { code: "3108", name: "Spain" },
  { code: "3200", name: "South Eastern Europe" },
  { code: "3201", name: "Albania" },
  { code: "3202", name: "Bosnia & Herzegovina" },
  { code: "3203", name: "Bulgaria" },
  { code: "3204", name: "Croatia" },
  { code: "3205", name: "Cyprus" },
  { code: "3206", name: "Former Yugoslav Republic of Macedonia (F" },
  { code: "3207", name: "Greece" },
  { code: "3208", name: "Moldova" },
  { code: "3211", name: "Romania" },
  { code: "3212", name: "Slovenia" },
  { code: "3213", name: "Serbia & Montenegro" },
  { code: "3300", name: "Eastern Europe" },
  { code: "3301", name: "Belarus" },
  { code: "3302", name: "Czech Republic" },
  { code: "3303", name: "Estonia" },
  { code: "3304", name: "Hungary" },
  { code: "3305", name: "Latvia" },
  { code: "3306", name: "Lithuania" },
  { code: "3307", name: "Poland" },
  { code: "3308", name: "Russian Federation" },
  { code: "3311", name: "Slovakia" },
  { code: "3312", name: "Ukraine" },
  { code: "4000", name: "North Africa & the Middle East" },
  { code: "4100", name: "North Africa" },
  { code: "4101", name: "Algeria" },
  { code: "4102", name: "Egypt" },
  { code: "4103", name: "Libya" },
  { code: "4104", name: "Morocco" },
  { code: "4105", name: "Sudan" },
  { code: "4106", name: "Tunisia" },
  { code: "4107", name: "Western Sahara" },
  { code: "4199", name: "North Africa, nec" },
  { code: "4200", name: "Middle East" },
  { code: "4201", name: "Bahrain" },
  { code: "4202", name: "Gaza Strip & West Bank" },
  { code: "4203", name: "Iran" },
  { code: "4204", name: "Iraq" },
  { code: "4205", name: "Israel" },
  { code: "4206", name: "Jordan" },
  { code: "4207", name: "Kuwait" },
  { code: "4208", name: "Lebanon" },
  { code: "4211", name: "Oman" },
  { code: "4212", name: "Qatar" },
  { code: "4213", name: "Saudi Arabia" },
  { code: "4214", name: "Syria" },
  { code: "4215", name: "Turkey" },
  { code: "4216", name: "United Arab Emirates" },
  { code: "4217", name: "Yemen" },
  { code: "5000", name: "South-East Asia" },
  { code: "5100", name: "Mainland South-East Asia" },
  { code: "5101", name: "Burma (Myanmar)" },
  { code: "5102", name: "Cambodia" },
  { code: "5103", name: "Laos" },
  { code: "5104", name: "Thailand" },
  { code: "5105", name: "Viet Nam" },
  { code: "5200", name: "Maritime South-East Asia" },
  { code: "5201", name: "Brunei Darussalam" },
  { code: "5202", name: "Indonesia" },
  { code: "5203", name: "Malaysia" },
  { code: "5204", name: "Philippines" },
  { code: "5205", name: "Singapore" },
  { code: "5206", name: "East Timor" },
  { code: "6000", name: "North-East Asia" },
  { code: "6100", name: "Chinese Asia (incl. Mongolia)" },
  { code: "6101", name: "China (excl. SARs & Taiwan Province)" },
  { code: "6102", name: "Hong Kong (SAR of China)" },
  { code: "6103", name: "Macau (SAR of China)" },
  { code: "6104", name: "Mongolia" },
  { code: "6105", name: "Taiwan" },
  { code: "6200", name: "Japan & the Koreas" },
  { code: "6201", name: "Japan" },
  { code: "6202", name: "Korea, Democratic People's Republic of (" },
  { code: "6203", name: "Korea, Republic of (South)" },
  { code: "7000", name: "Southern & Central Asia" },
  { code: "7100", name: "Southern Asia" },
  { code: "7101", name: "Bangladesh" },
  { code: "7102", name: "Bhutan" },
  { code: "7103", name: "India" },
  { code: "7104", name: "Maldives" },
  { code: "7105", name: "Nepal" },
  { code: "7106", name: "Pakistan" },
  { code: "7107", name: "Sri Lanka" },
  { code: "7200", name: "Central Asia" },
  { code: "7201", name: "Afghanistan" },
  { code: "7202", name: "Armenia" },
  { code: "7203", name: "Azerbaijan" },
  { code: "7204", name: "Georgia" },
  { code: "7205", name: "Kazakhstan" },
  { code: "7206", name: "Kyrgyz Republic" },
  { code: "7207", name: "Tajikistan" },
  { code: "7208", name: "Turkmenistan" },
  { code: "7211", name: "Uzbekistan" },
  { code: "8000", name: "Americas" },
  { code: "8100", name: "Northern America" },
  { code: "8101", name: "Bermuda" },
  { code: "8102", name: "Canada" },
  { code: "8103", name: "St Pierre & Miquelon" },
  { code: "8104", name: "United States of America" },
  { code: "8200", name: "South America" },
  { code: "8201", name: "Argentina" },
  { code: "8202", name: "Bolivia" },
  { code: "8203", name: "Brazil" },
  { code: "8204", name: "Chile" },
  { code: "8205", name: "Colombia" },
  { code: "8206", name: "Ecuador" },
  { code: "8207", name: "Falkland Islands" },
  { code: "8208", name: "French Guiana" },
  { code: "8211", name: "Guyana" },
  { code: "8212", name: "Paraguay" },
  { code: "8213", name: "Peru" },
  { code: "8214", name: "Suriname" },
  { code: "8215", name: "Uruguay" },
  { code: "8216", name: "Venezuela" },
  { code: "8299", name: "South America, nec" },
  { code: "8300", name: "Central America" },
  { code: "8301", name: "Belize" },
  { code: "8302", name: "Costa Rica" },
  { code: "8303", name: "El Salvador" },
  { code: "8304", name: "Guatemala" },
  { code: "8305", name: "Honduras" },
  { code: "8306", name: "Mexico" },
  { code: "8307", name: "Nicaragua" },
  { code: "8308", name: "Panama" },
  { code: "8400", name: "Caribbean" },
  { code: "8401", name: "Anguilla" },
  { code: "8402", name: "Antigua & Barbuda" },
  { code: "8403", name: "Aruba" },
  { code: "8404", name: "Bahamas" },
  { code: "8405", name: "Barbados" },
  { code: "8406", name: "Cayman Islands" },
  { code: "8407", name: "Cuba" },
  { code: "8408", name: "Dominica" },
  { code: "8411", name: "Dominican Republic" },
  { code: "8412", name: "Grenada" },
  { code: "8413", name: "Guadeloupe" },
  { code: "8414", name: "Haiti" },
  { code: "8415", name: "Jamaica" },
  { code: "8416", name: "Martinique" },
  { code: "8417", name: "Montserrat" },
  { code: "8418", name: "Netherlands Antilles" },
  { code: "8421", name: "Puerto Rico" },
  { code: "8422", name: "St Kitts & Nevis" },
  { code: "8423", name: "St Lucia" },
  { code: "8424", name: "St Vincent & the Grenadines" },
  { code: "8425", name: "Trinidad & Tobago" },
  { code: "8426", name: "Turks & Caicos Islands" },
  { code: "8427", name: "Virgin Islands, British" },
  { code: "8428", name: "Virgin Islands, United States" },
  { code: "9000", name: "Sub-Saharan Africa" },
  { code: "9100", name: "Central & West Africa" },
  { code: "9101", name: "Benin" },
  { code: "9102", name: "Burkina Faso" },
  { code: "9103", name: "Cameroon" },
  { code: "9104", name: "Cape Verde" },
  { code: "9105", name: "Central African Republic" },
  { code: "9106", name: "Chad" },
  { code: "9107", name: "Congo" },
  { code: "9108", name: "Congo, Democratic Republic of" },
  { code: "9111", name: "Côte d'Ivoire" },
  { code: "9112", name: "Equatorial Guinea" },
  { code: "9113", name: "Gabon" },
  { code: "9114", name: "Gambia" },
  { code: "9115", name: "Ghana" },
  { code: "9116", name: "Guinea" },
  { code: "9117", name: "Guinea-Bissau" },
  { code: "9118", name: "Liberia" },
  { code: "9121", name: "Mali" },
  { code: "9122", name: "Mauritania" },
  { code: "9123", name: "Niger" },
  { code: "9124", name: "Nigeria" },
  { code: "9125", name: "Sao Tome & Principe" },
  { code: "9126", name: "Senegal" },
  { code: "9127", name: "Sierra Leone" },
  { code: "9128", name: "Togo" },
  { code: "9200", name: "Southern & East Africa" },
  { code: "9201", name: "Angola" },
  { code: "9202", name: "Botswana" },
  { code: "9203", name: "Burundi" },
  { code: "9204", name: "Comoros" },
  { code: "9205", name: "Djibouti" },
  { code: "9206", name: "Eritrea" },
  { code: "9207", name: "Ethiopia" },
  { code: "9208", name: "Kenya" },
  { code: "9211", name: "Lesotho" },
  { code: "9212", name: "Madagascar" },
  { code: "9213", name: "Malawi" },
  { code: "9214", name: "Mauritius" },
  { code: "9215", name: "Mayotte" },
  { code: "9216", name: "Mozambique" },
  { code: "9217", name: "Namibia" },
  { code: "9218", name: "Reunion" },
  { code: "9221", name: "Rwanda" },
  { code: "9222", name: "St Helena" },
  { code: "9223", name: "Seychelles" },
  { code: "9224", name: "Somalia" },
  { code: "9225", name: "South Africa" },
  { code: "9226", name: "Swaziland" },
  { code: "9227", name: "Tanzania" },
  { code: "9228", name: "Uganda" },
  { code: "9231", name: "Zambia" },
  { code: "9232", name: "Zimbabwe" },
  { code: "9299", name: "Southern & East Africa, nec" },
];
