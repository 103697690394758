import React, { createContext, useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const initialState = {};
export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  const [studentId, setStudentId] = useState(null);
  const [step, setStep] = useState(-1);
  const [schools, setSchools] = useState([]);
  const [isKinder, setIsKinder] = useState(false);
  const [contact, setContact] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [permits, setPermits] = useState([]);
  const [role, setRole] = useState(null);
  const [yol, setYol] = useState(true);
  useEffect(() => {
    AdminService.getSchools().then((data) => {
      setSchools(data.data);
    });
  }, []);
  return (
    <GlobalContext.Provider
      value={{
        yol,
        setYol,
        permits,
        setPermits,
        role,
        setRole,
        studentId,
        schools,
        selectedCampus,
        setSelectedCampus,
        setStudentId,
        step,
        setStep,
        okullar: schools,
        isKinder,
        setIsKinder,
        contact,
        setContact,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
