import { ArrowRightOutlined, ClearOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { Countries } from "src/resources/countries";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { languages } from "src/resources/languages";
import { GlobalContext } from "../context/GlobalContext";
import Occupation from "./Occupation";
import TitleBar from "./TitleBar";

const Step3Form = () => {
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const [isGra1, setIsGra1] = useState(false);
  const [isGra2, setIsGra2] = useState(false);
  const [isRelation, setIsRelation] = useState(false);
  const [isRelation2, setIsRelation2] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { studentId, setStep, step } = useContext(GlobalContext);
  const router = useHistory();
  useEffect(() => {
    if (step === -1) {
      router.push("/");
    }
    setStep(2);
  }, [router, setStep, step]);

  const onFinish = (values) => {
    console.log(values);
    values = { ...values, studentId };
    AdminService.newFamily(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Step-3 completed",
      });
      router.replace("/home/step4");
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const graChanged = (s) => {
    console.log(s);
    setIsGra1(s === "Yes" ? true : false);
  };
  const graChanged2 = (s) => {
    console.log(s);
    setIsGra2(s === "Yes" ? true : false);
  };
  const relationChanged = (s) => {
    console.log(s);
    setIsRelation(s === "Guardian" ? true : false);
  };
  const relationChanged2 = (s) => {
    console.log(s);
    setIsRelation2(s === "Guardian" ? true : false);
  };
  const [states, setStates] = useState([
    {
      code: "ACT",
      name: "Australian Capital Territory",
      subdivision: null,
    },
    {
      code: "NSW",
      name: "New South Wales",
      subdivision: null,
    },
    {
      code: "NT",
      name: "Northern Territory",
      subdivision: null,
    },
    {
      code: "QLD",
      name: "Queensland",
      subdivision: null,
    },
    {
      code: "SA",
      name: "South Australia",
      subdivision: null,
    },
    {
      code: "TAS",
      name: "Tasmania",
      subdivision: null,
    },
    {
      code: "VIC",
      name: "Victoria",
      subdivision: null,
    },
    {
      code: "WA",
      name: "Western Australia",
      subdivision: null,
    },
  ]);

  return (
    <div className="max-w-4xl mx-auto  bg-f5f5f5 p-2 lg:p-6 lg:border lg:border-gray-300">
      <Modal
        visible={modalVisible}
        width={1200}
        closable
        onCancel={() => setModalVisible(false)}
        onOk={() => setModalVisible(false)}
      >
        <Occupation />
      </Modal>
      <TitleBar title="FAMILY DETAILS" />

      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        layout={lg ? "horizontal" : "inline"}
        {...GlobalVariables.formLayout}
        initialValues={{ p1cob: "1101", p2cob: "1101" }}
      >
        <Form.Item>
          <Divider orientation="left">PARENT - 1</Divider>
        </Form.Item>
        <Form.Item
          label="Relation of Child"
          name="p1relation"
          rules={[{ required: true, message: "Relation is required" }]}
        >
          <Select
            placeholder="Plase Select"
            style={{ width: 180 }}
            onChange={relationChanged}
          >
            <Select.Option value="Mother">Mother</Select.Option>
            <Select.Option value="Father">Father</Select.Option>
            <Select.Option value="Guardian">Guardian</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Are you an Amity graduate?"
          name="p1graduate"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Select
            placeholder="Plase Select"
            style={{ width: 180 }}
            onChange={graChanged}
          >
            <Select.Option value="No">No</Select.Option>
            <Select.Option value="Yes">Yes</Select.Option>
          </Select>
        </Form.Item>
        {isGra1 && (
          <Form.Item
            label="Class Of"
            name="p1graclass"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Please Type " />
          </Form.Item>
        )}
        <Form.Item label="Given Name(s)" style={{ marginBottom: 0 }} required>
          <Form.Item
            style={{ display: "inline-block" }}
            name="p1title"
            rules={[{ required: true, message: "This Field is required" }]}
          >
            <Select style={{ width: 80 }} placeholder="Title">
              {GlobalVariables.titles.map((c) => (
                <Select.Option key={c} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="p1givenName"
            rules={[{ required: true, message: "This Field is required" }]}
            style={{ display: "inline-block", width: "calc(100% - 100px)" }}
          >
            <Input placeholder="Please type given name(s)" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Family Name"
          name="p1familyName"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input placeholder="Please Type Family Name " />
        </Form.Item>
        {isRelation && (
          <Form.Item label="Next of kin" name="p1nok">
            <Input placeholder="Please Type Relation" />
          </Form.Item>
        )}
        <Form.Item
          label="Address"
          name="p1address"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input placeholder="Please Type Address " />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Form.Item
            rules={[{ required: true, message: "This field is required!" }]}
            name="p1suburb"
            style={{ display: "inline-block", width: "calc(33.33%)" }}
          >
            <Input placeholder="Suburb" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "This field is required!" }]}
            name="p1state"
            style={{ display: "inline-block", width: "calc(33.33%)" }}
          >
            <Select placeholder="Select State">
              {states.map((s) => (
                <Select.Option key={s.code} value={s.code}>
                  {s.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ display: "inline-block", width: "calc(33.33%)" }}
            name="p1postcode"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Post code " />
          </Form.Item>
        </Form.Item>
        <Form.Item className="-mt-6" label="Phones" style={{ marginBottom: 0 }}>
          <Form.Item
            name="p1mPhone"
            rules={[{ required: true, message: "This field is required!" }]}
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Mobile Phone" />
          </Form.Item>

          <Form.Item
            name="p1hPhone"
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Home Phone" />
          </Form.Item>
          <Form.Item
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
            name="p1wPhone"
          >
            <Input placeholder="Work Phone" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="E-Mail"
          name="p1mail"
          rules={[
            { required: true, message: "This field is required!" },
            { type: "email", message: "Wrong E-mail address" },
          ]}
        >
          <Input placeholder="Please Type E-Mail " />
        </Form.Item>
        <Form.Item
          label="Country Of Birth"
          name="p1cob"
          rules={[{ required: true, message: "COB is required!" }]}
        >
          <Select
            showSearch
            style={{ width: 180 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Countries.map((c) => (
              <Select.Option key={c.code} value={c.code}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Ethnic Background" name="p1ethnic">
          <Input placeholder="Please type ethnic bacground" />
        </Form.Item>

        <Form.Item
          label="Language(s) Spoken at Home"
          name="p1languages"
          rules={[{ required: true, message: "Languges are required" }]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pleace Select Languages"
            mode="multiple"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {languages
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((c) => (
                <Select.Option key={c.code} value={c.code}>
                  {c.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <span>
              Highest year of
              <br /> primary or secondary
            </span>
          }
          name="p1prisec"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Select style={{ width: 280 }} placeholder="Please Select">
            {GlobalVariables.prisec.map((c) => (
              <Select.Option key={c} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Level of highest qualification"
          name="p1diploma"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Select style={{ width: 280 }} placeholder="Please Select">
            {GlobalVariables.diplomas.map((c) => (
              <Select.Option key={c} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="What is your current occupation?" name="p1occupation">
          <Input placeholder="Please type occupation" />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Button
            type="link"
            onClick={() => setModalVisible(true)}
            className="text-sky-500 font-semibold"
          >
            Click for the list of occupational groups.
          </Button>
        </Form.Item>
        <Form.Item label="What is your occupation group?" name="p1ogroup">
          <Select style={{ width: 280 }} placeholder="Please Select">
            {[1, 2, 3, 4].map((c) => (
              <Select.Option key={c} value={c}>
                Group - {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Company/Employer Name" name="p1company">
          <Input placeholder="Please type name" />
        </Form.Item>
        {/* ###########################PARENT2################################## */}
        <Form.Item>
          <Divider orientation="left">PARENT - 2</Divider>
        </Form.Item>
        <Form.Item
          label="Relation of Child"
          name="p2relation"
          rules={[{ required: true, message: "Relation is required" }]}
        >
          <Select
            placeholder="Plase Select"
            style={{ width: 180 }}
            onChange={relationChanged2}
          >
            <Select.Option value="Mother">Mother</Select.Option>
            <Select.Option value="Father">Father</Select.Option>
            <Select.Option value="Guardian">Guardian</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Are you an Amity graduate?"
          name="p2graduate"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Select
            placeholder="Plase Select"
            style={{ width: 180 }}
            onChange={graChanged2}
          >
            <Select.Option value="No">No</Select.Option>
            <Select.Option value="Yes">Yes</Select.Option>
          </Select>
        </Form.Item>
        {isGra2 && (
          <Form.Item
            label="Class Of"
            name="p2graclass"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Please Type " />
          </Form.Item>
        )}
        <Form.Item label="Given Name(s)" style={{ marginBottom: 0 }} required>
          <Form.Item
            style={{ display: "inline-block" }}
            name="p2title"
            rules={[{ required: true, message: "This Field is required" }]}
          >
            <Select style={{ width: 80 }} placeholder="Title">
              {GlobalVariables.titles.map((c) => (
                <Select.Option key={c} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="p2givenName"
            rules={[{ required: true, message: "This Field is required" }]}
            style={{ display: "inline-block", width: "calc(100% - 100px)" }}
          >
            <Input placeholder="Please type given name(s)" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Family Name"
          name="p2familyName"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input placeholder="Please Type Family Name " />
        </Form.Item>
        {isRelation2 && (
          <Form.Item label="Next of kin" name="p2nok">
            <Input placeholder="Please Type Relation" />
          </Form.Item>
        )}
        <Form.Item
          label="Address"
          name="p2address"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input placeholder="Please Type Address " />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Form.Item
            rules={[{ required: true, message: "This field is required!" }]}
            name="p2suburb"
            style={{ display: "inline-block", width: "calc(33.33%)" }}
          >
            <Input placeholder="Suburb" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "This field is required!" }]}
            name="p2state"
            style={{ display: "inline-block", width: "calc(33.33%)" }}
          >
            <Select placeholder="Select State">
              {states.map((s) => (
                <Select.Option key={s.code} value={s.code}>
                  {s.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ display: "inline-block", width: "calc(33.33%)" }}
            name="p2postcode"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Post code " />
          </Form.Item>
        </Form.Item>

        <Form.Item className="-mt-6" label="Phones" style={{ marginBottom: 0 }}>
          <Form.Item
            rules={[{ required: true, message: "This field is required!" }]}
            name="p2mPhone"
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Mobile Phone" />
          </Form.Item>

          <Form.Item
            name="p2hPhone"
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Home Phone" />
          </Form.Item>
          <Form.Item
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
            name="p2wPhone"
          >
            <Input placeholder="Work Phone" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="E-Mail"
          name="p2mail"
          rules={[
            { required: true, message: "This field is required!" },
            { type: "email", message: "Wrong E-mail address" },
          ]}
        >
          <Input placeholder="Please Type E-Mail " />
        </Form.Item>
        <Form.Item
          label="Country Of Birth"
          name="p2cob"
          rules={[{ required: true, message: "COB is required!" }]}
        >
          <Select
            showSearch
            style={{ width: 180 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Countries.map((c) => (
              <Select.Option key={c.code} value={c.code}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Ethnic Background" name="p2ethnic">
          <Input placeholder="Please type ethnic bacground" />
        </Form.Item>

        <Form.Item
          label="Language(s) Spoken at Home"
          name="p2languages"
          rules={[{ required: true, message: "Languges are required" }]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pleace Select Languages"
            mode="multiple"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {languages
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((c) => (
                <Select.Option key={c.code} value={c.code}>
                  {c.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <span>
              Highest year of
              <br /> primary or secondary
            </span>
          }
          name="p2prisec"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Select style={{ width: 280 }} placeholder="Please Select">
            {GlobalVariables.prisec.map((c) => (
              <Select.Option key={c} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Level of highest qualification"
          name="p2diploma"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Select style={{ width: 280 }} placeholder="Please Select">
            {GlobalVariables.diplomas.map((c) => (
              <Select.Option key={c} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="What is your current occupation?" name="p2occupation">
          <Input placeholder="Please type occupation" />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Button
            type="link"
            onClick={() => setModalVisible(true)}
            className="text-sky-500 font-semibold"
          >
            Click for the list of occupational groups.
          </Button>
        </Form.Item>
        <Form.Item label="What is your occupation group?" name="p2ogroup">
          <Select style={{ width: 280 }} placeholder="Please Select">
            {[1, 2, 3, 4].map((c) => (
              <Select.Option key={c} value={c}>
                Group - {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Company/Employer Name" name="p2company">
          <Input placeholder="Please type name" />
        </Form.Item>
        {/* ###########################PARENT2################################## */}
        <Form.Item>
          <Divider orientation="left">FAMILY DETAILS</Divider>
        </Form.Item>
        <Form.Item label="Child lives with" name="liveswith">
          <Select style={{ width: 280 }} placeholder="Please Select">
            {["Both Parents", "Mother", "Father", "Guardian"].map((c) => (
              <Select.Option key={c} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Are there any custody restrictions" name="custody">
          <Select style={{ width: 280 }} placeholder="Please Select">
            {["No", "Yes"].map((c) => (
              <Select.Option key={c} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Siblings-1" style={{ marginBottom: 0 }}>
          <Form.Item
            name="s1name"
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="s1age"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Age" />
          </Form.Item>
          <Form.Item
            name="s1grade"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Grade" />
          </Form.Item>
          <Form.Item
            name="s1attending"
            style={{ display: "inline-block", width: "calc(33% )" }}
          >
            <Input placeholder="School currently attending" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Siblings-2" style={{ marginBottom: 0 }}>
          <Form.Item
            name="s2name"
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="s2age"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Age" />
          </Form.Item>
          <Form.Item
            name="s2grade"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Grade" />
          </Form.Item>
          <Form.Item
            name="s2attending"
            style={{ display: "inline-block", width: "calc(33% )" }}
          >
            <Input placeholder="School currently attending" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Siblings-3" style={{ marginBottom: 0 }}>
          <Form.Item
            name="s3name"
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="s3age"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Age" />
          </Form.Item>
          <Form.Item
            name="s3grade"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Grade" />
          </Form.Item>
          <Form.Item
            name="s3attending"
            style={{ display: "inline-block", width: "calc(33% )" }}
          >
            <Input placeholder="School currently attending" />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Siblings-4" style={{ marginBottom: 0 }}>
          <Form.Item
            name="s4name"
            style={{ display: "inline-block", width: "calc(33% - 12px)" }}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="s4age"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Age" />
          </Form.Item>
          <Form.Item
            name="s4grade"
            style={{ display: "inline-block", width: "calc(15% - 12px)" }}
          >
            <Input placeholder="Grade" />
          </Form.Item>
          <Form.Item
            name="s4attending"
            style={{ display: "inline-block", width: "calc(33% )" }}
          >
            <Input placeholder="School currently attending" />
          </Form.Item>
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
          <Space>
            <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
              Clear
            </Button>
            <Button type="primary" htmlType="submit">
              Next <ArrowRightOutlined />
            </Button>
            <Button onClick={() => router.push("/home/step4")}>
              <ArrowRightOutlined />
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step3Form;
