export const GlobalVariables = {
  formLayout: {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  },
  tailLayout: {
    wrapperCol: {
      offset: 6,
      span: 18,
    },
  },
  titles: ["Mr", "Mrs", "Miss", "Ms", "Other"],
  diplomas: [
    "Bachelor Degree or above",
    "Advanced Diploma and/or diploma",
    "Certificate I to IV (including trade certificate)",
    "No post secondary education",
  ],
  prisec: ["Year 12 or equivalent", "Year 10 or equivalent", "Below year 10"],
  compare: (a, b) => {
    var atitle = a || "";
    var btitle = b || "";
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  },
};
