import { Card, Statistic } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { Countries } from "src/resources/countries";
import { languages } from "src/resources/languages";
import { GlobalContext } from "../context/GlobalContext";

const AdminPage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [values, setValues] = useState(null);
  const { schools } = useContext(GlobalContext);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getDashReports().then((data) => {
        console.log(data.data);
        setValues(data.data);
      });
    }
  }, [initialRender]);
  const gridStyle = {
    width: "25%",
    hoverable: true,
    padding: 15,
  };
  const prompts = [
    { label: "Reputation of Amity College", value: 1 },
    { label: "Academic excellence", value: 2 },
    { label: "Extracurricular activities", value: 3 },
    { label: "School values and ethos", value: 4 },
    { label: "Pastoral care programs", value: 5 },
    { label: "Continuing family tradition", value: 6 },
    { label: "Close to home", value: 7 },
    { label: "Other", value: 8 },
  ];
  const getPromptName = (value) => {
    return prompts[prompts.findIndex((p) => p.value === value)]["label"];
  };
  const getLearnName = (value) => {
    return learns[learns.findIndex((p) => p.value === value)]["label"];
  };
  const learns = [
    { label: "We are current Amity College parents", value: 1 },
    { label: "Family/friends", value: 2 },
    { label: "Past students", value: 3 },
    { label: "School guides and listings", value: 4 },
    { label: "Newspaper advertisement", value: 5 },
    { label: "Social media advertisement", value: 6 },
    { label: "School’s webpage", value: 7 },
    { label: "Other", value: 8 },
  ];

  const getCountryName = (code) => {
    return Countries[Countries.findIndex((c) => c.code === code)]["name"];
  };
  const getLanguageName = (code) => {
    return languages[languages.findIndex((c) => c.code === code)]["name"];
  };
  const getSchoolName = (code) => {
    return schools[schools.findIndex((c) => c.id === code)]["name"];
  };
  return (
    <div className="space-y-4 max-w-5xl mx-auto">
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">Students</span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            <Card.Grid style={gridStyle}>
              <Statistic title="Total" value={values.students} />
            </Card.Grid>
            {values.genders.length > 0 &&
              values.genders.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={`Gender-${c.name}`} value={c.count} />
                </Card.Grid>
              ))}
            <Card.Grid style={gridStyle}>
              <Statistic
                title="Invoices / Amount"
                value={`${values.invoices} Invoices / $${
                  values.invoices * 100
                }`}
              />
            </Card.Grid>
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">Campus</span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.schools.length > 0 &&
              values.schools.map((c) => (
                <Card.Grid
                  className="cursor-pointer"
                  style={gridStyle}
                  key={c.name}
                  // @ts-ignore
                  onClick={() => router.push(`/admin/students`)}
                >
                  <Statistic title={getSchoolName(c.name)} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">Years</span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.years.length > 0 &&
              values.years.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={c.name} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>{" "}
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">
            Students Country Of Birth
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.scob.length > 0 &&
              values.scob.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={getCountryName(c.name)} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">
            Parents Country Of Birth
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.pcob.length > 0 &&
              values.pcob.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={getCountryName(c.name)} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">
            Students Home Languages
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.slangs.length > 0 &&
              values.slangs.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={getLanguageName(c.name)} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">
            Parents Home Languages
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.plangs.length > 0 &&
              values.plangs.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={getLanguageName(c.name)} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">
            Child Lives With
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.lives.length > 0 &&
              values.lives.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={c.name} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">
            Occupation Groups
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.ogroups.length > 0 &&
              values.ogroups.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={`Group-${c.name}`} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">
            Citizenship / Visa Status
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.status.length > 0 &&
              values.status.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={c.name} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 text-base font-semibold">Covers</span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.ambCovers.length > 0 &&
              values.ambCovers.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic
                    title={`Ambulance Cover-${c.name}`}
                    value={c.count}
                  />
                </Card.Grid>
              ))}
            {values.fundCovers.length > 0 &&
              values.fundCovers.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic
                    title={`Health fund cover-${c.name}`}
                    value={c.count}
                  />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 font-semibold">
            Feedback / What has prompted you to apply for enrolment?
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.prompts.length > 0 &&
              values.prompts.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={getPromptName(c.name)} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      {values && (
        <Card size="small">
          <Card.Meta
            title="Specified feedbacks"
            description={
              values.pFeeds.length > 0 &&
              values.pFeeds.map((f, index) => (
                <span key={index}>{f.feed} | </span>
              ))
            }
          />
        </Card>
      )}
      <Card
        className="border border-gray-300"
        type="inner"
        title={
          <span className="text-sky-500 font-semibold">
            Feedback / How did you learn about Amity College?
          </span>
        }
        loading={values === null}
        size="small"
      >
        {values && (
          <>
            {values.learns.length > 0 &&
              values.learns.map((c) => (
                <Card.Grid style={gridStyle} key={c.name}>
                  <Statistic title={getLearnName(c.name)} value={c.count} />
                </Card.Grid>
              ))}
          </>
        )}
      </Card>
      {values && (
        <Card size="small">
          <Card.Meta
            title="Specified feedbacks"
            description={
              values.lFeeds.length > 0 &&
              values.lFeeds.map((f, index) => (
                <span key={index}>{f.feed} | </span>
              ))
            }
          />
        </Card>
      )}
    </div>
  );
};

export default AdminPage;
