import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router";
import TitleBar from "./TitleBar";

const Occupation = () => {
  const router = useHistory();
  return (
    <div className="px-2 lg:px-4 py-6  overflow-hidden lg:pb-24">
      <img
        className="relative mx-auto"
        width={200}
        src={require("../../assets/images/logo.png").default}
        alt=""
      />
      <p className="text-3xl font-bold text-sky-500 text-center">
        LIST OF PARENTAL OCCUPATION GROUPS
      </p>
      <div className="p-2 lg:p-4 text-base mx-auto max-w-6xl space-y-2">
        <TitleBar title="GROUP 1" />
        <p className="text-lg font-bold">
          Senior Management in Large Business Organisation, Government
          Administration and Defence, and Qualified Professionals{" "}
        </p>
        <p>
          • Senior executive/manager/department head in industry, commerce,
          media or other large organisations{" "}
        </p>
        <p>
          • Public service manager (section head or above), regional director,
          health/education/police/fire service administrator{" "}
        </p>
        <p>
          • Other administrator (school principal, faculty head/dean, library/
          museum/gallery, research facility director){" "}
        </p>
        <p>• Defence forces commissioned officer </p>
        <p>
          • Professionals generally have degree or higher qualifications and
          experience in applying this knowledge to design, develop or operate
          complex systems; identify, treat and advice on problems; and teach
          others{" "}
        </p>
        <div className="pl-2 lg:pl-6">
          <p>
            o Health, education, law, social welfare, engineering, science,
            computing professional{" "}
          </p>
          <p>
            o Business (management consultant, business analyst, accountant,
            auditor, policy analyst, actuary, valuer){" "}
          </p>
          <p>
            o Air/sea transport (aircraft/ship's caption/officer/pilot, flight
            officer, flying instructor, air traffic controller{" "}
          </p>
        </div>
        <p></p>
        <TitleBar title="GROUP 2" />
        <p className="text-lg font-bold">
          Other Business Managers, Arts/Media/Sportspersons and Associate
          Professionals
        </p>
        <p>
          • Owner/manager of farm, construction, import/export, wholesale,
          manufacturing, transport, real estate business{" "}
        </p>
        <p>
          • Specialist manager (finance/engineering/production/personnel/
          industrial relations/sales/marketing){" "}
        </p>
        <p>
          • Financial services manager (bank branch manager,
          finance/investment/insurance broker, credit/loans officer){" "}
        </p>
        <p>
          • Retail sales/services manager (shop, petrol station, restaurant,
          club, hotel/motel, cinema, theatre, agency){" "}
        </p>
        <p>
          • Arts/media/sports (musician, actor, dancer, painter, potter,
          sculptor, journalist, author, media presenter, photographer, designer,
          illustrator, proof reader, sportsman/woman, coach, trainer, sports
          official){" "}
        </p>
        <p>
          • Associate professionals generally have diploma/technical
          qualifications and support managers and professionals{" "}
        </p>{" "}
        <div className="pl-2 lg:pl-6">
          <p>
            o Health, education, law, social welfare, engineering, science,
            computing technician/associate professional{" "}
          </p>
          <p>o Business/administration </p>
          <p>
            (recruitment/employment/industrial relations/training officer,
            marketing/advertising specialist, market research analyst, technical
            sales representative, retail buyer, office/project manager)
          </p>
          <p> o Defence forces senior non-commissioned officer </p>
        </div>
        <p></p>
        <TitleBar title="GROUP 3" />
        <p className="text-lg font-bold">
          Tradesmen/Women, Clerks and Skilled Office, Sales and Service Staff
        </p>
        <p>
          • Tradesmen/women generally have completed 4 year trade certificate,
          usually by apprenticeship. All tradesmen/women are included in this
          group{" "}
        </p>
        <p>
          • Clerks (bookkeeper, bank/PO clerk, statistical/actuarial clerk,
          accounting/claims/audit clerk, payroll clerk recording/registry/filing
          clerk, betting clerk, stores/inventory clerk, purchasing/order clerk,
          freight/ transport/shipping clerk, bond clerk, customs agent, customer
          services clerk, admissions clerk){" "}
        </p>
        <p>
          • Skilled office, sales and service staff o Office (secretary,
          personal assistant, desktop publishing operator, switchboard operator){" "}
        </p>{" "}
        <div className="pl-2 lg:pl-6">
          <p>
            o Sales (company sales representative, auctioneer, insurance
            agent/assessor/loss adjuster, market researcher){" "}
          </p>
          <p>
            o Service (aged/disabled/refuge/child care worker, nanny, meter
            reader, parking inspector, postal worker, courier, travel agent,
            tour guide, flight attendant, fitness instructor, casino
            dealer/supervisor){" "}
          </p>
        </div>
        <TitleBar title="GROUP 4" />
        <p className="text-lg font-bold">
          Machine Operators, Hospitality Staff, Assistants, Laborers and Related
          Workers
        </p>
        <p>
          • Drivers, mobiles plant, production/processing machinery and other
          machinery operators • Hospitality staff (hotel service supervisor,
          receptionist, waiter, bar attendant, kitchen-hand, porter,
          housekeeper){" "}
        </p>
        <p>• Office assistants, sales assistants and other assistants </p>
        <div className="pl-2 lg:pl-6">
          <p>
            o Office (typist, word processing/data entry/business machine
            operator, receptionist, office assistant){" "}
          </p>
          <p>
            o Sales (sales assistant, motor vehicle/caravan/parts salesperson,
            checkout operator, cashier, bus/train conductor, ticket seller,
            service station attendant, car rental desk staff, street vendor,
            telemarketer, shelf stacker){" "}
          </p>
        </div>
        <p>
          • Assistant/aide (trades' assistant, school/teacher's aide, dental
          assistant, veterinary nurse, nursing assistant, museum/gallery
          attendant, usher, home helper, salon assistant, animal attendant){" "}
        </p>
        <p>• Labourers and related workers </p>
        <div className="pl-2 lg:pl-6">
          <p>o Defence forces ranks below senior NCO not included above </p>
          <p>
            o Agriculture, horticulture, forestry, fishing, mining worker (farm
            overseer, shearer, wool/hide classer, farm hand, horse trainer,
            nurseryman, greenkeeper, gardener, tree surgeon, forestry/logging
            worker, miner, seafarer/fishing hand){" "}
          </p>
        </div>
        <p>
          • Other worker (labourer, factory hand, storeman, guard, cleaner,
          caretaker, laundry worker, trolley collector, car park attendant,
          crossing supervisor{" "}
        </p>
        <p></p>
      </div>
    </div>
  );
};

export default Occupation;
