import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router";

const Conditions = () => {
  const router = useHistory();
  return (
    <div className="px-2 lg:px-4 py-6  overflow-hidden lg:pb-24">
      <Button
        type="primary"
        icon={<ArrowLeftOutlined />}
        onClick={() => router.goBack()}
      >
        Back
      </Button>
      <img
        className="relative mx-auto"
        width={200}
        src={require("../../assets/images/logo.png").default}
        alt=""
      />
      <p className="text-3xl font-bold text-sky-500 text-center">
        CONDITIONS OF ENROLMENT
      </p>
      <div className="p-2 lg:p-4 text-base mx-auto max-w-6xl space-y-2">
        <Button block type="primary" className="text-left mb-2 " size="large">
          ADMISSION
        </Button>
        <p>
          <b> 1.</b> Admissions to Amity College are subject to vacancy,
          successful examination and interview process. Application for
          Enrolment Form and application fee are valid only for the school year
          applied. Where possible, preference is given to siblings of current
          students, children of current staff members and descendants of alumni.
        </p>
        <p>
          <b> 2.</b> Students applying for Years 1-12 will sit an entrance
          examination on the designated date. Admissions to Kindergarten are
          subject to a school readiness interview followed by a class
          observation.
        </p>
        <p>
          <b> 3. </b>Should the result of the entrance exam be satisfactory, an
          interview with the school will be arranged where the parents/guardian
          and student attend, providing the school with
        </p>
        <div className="pl-2 lg:pl-6">
          <p>a. recent school reports – last two years</p>
          <p>b. NAPLAN test results (for year 4 to year10 applicants)</p>
          <p>c. certificates/awards</p>
        </div>
        <p>
          <b> 4.</b> If interview results are found to be satisfactory, a formal
          Offer of Enrolment will then be made. If the Offer of Enrolment is
          declined, the application is cancelled. Subsequent applications will
          be treated as a new application and any priority will be lost.
        </p>
        <p>
          <b> 5.</b> Your child’s place is secured once a non-refundable
          enrolment fee and a refundable security bond are paid within a
          fortnight of receiving the Offer of Enrolment.
        </p>
        <p>
          <b> 6.</b> Prior to commencement you will be invited to the
          Orientation Day where school rules, uniform, book lists, bus
          application (if applicable), subject selection (for high school) will
          be explained for a smooth transition.
        </p>
        <Button block type="primary" className="text-left mb-2" size="large">
          ATTENDANCE
        </Button>
        <p>
          <b> 7.</b> The student must attend school throughout the school year.
          In term time, attendance is required from Monday to Friday inclusive,
          during the hours prescribed in the Student Handbook.
        </p>
        <p>
          <b>8.</b> All students must participate in excursions/incursions,
          school camps, school sport programs, important school functions such
          as graduation ceremonies, end of year concerts, etc. as required by
          the Principal.
        </p>
        <p>
          <b> 9.</b> The College must be notified of a short term absence in
          advance by a parent/guardian; such leave will be granted only for
          medical or special reasons. In the case of absence due to infectious
          illness, accident, etc., the parent/guardian must notify the school as
          soon as possible. For an overseas trip, a “Student Long Term Absence
          Request Form” must be filled in and approved by the school
          administration before travel arrangements are made.
        </p>
        <p>
          <b> 10.</b> If a student has been absent without permission (e.g.
          minor illness), on return to school s/he must bring an explanatory
          letter from his/her parent/guardian or a medical certificate. A
          medical certificate is required in case of prolonged sickness.
        </p>
        <p>
          <b> 11.</b> In case of any unexplained absence, the parent/guardian
          will be contacted by the College and asked for an explanation for such
          absence. Unexplained prolonged absences will be reported to the
          relevant government agencies under mandatory reporting regulations.
        </p>
        <p>
          <b> 12.</b> The student’s enrolment will be reviewed by the school
          administration in case of more than 50 days of explained/unexplained
          absences in a school year. Please refer to the Student Handbook to
          find out more details about conditions and consequences of taking long
          term absences.
        </p>
        <Button block type="primary" className="text-left mb-2" size="large">
          ADMINISTRATION AND CONDUCT
        </Button>
        <p>
          <b> 13.</b> Parents/guardians are required to familiarise themselves
          with the spirit and ethos of the school and demonstrate a commitment
          to its philosophy, a respect for its rules and conditions and the
          intention to participate in the College life. Parents/guardians must
          accept and abide by new decisions and regulations regarding school
          practices in general made by the School Management and the Board.
        </p>
        <p>
          <b> 14.</b> Parents/guardians and their children will always observe
          courteous and respectful manners in communication with staff, students
          and parents. Confrontational and critical behaviour, sarcasm,
          derogatory remarks and offensive comments have no place at Amity
          College.
        </p>
        <p>
          <b> 15.</b> Parents/guardians are to attend parent-teacher interviews,
          parents information sessions/seminars, read the school’s newsletters
          and be ready to assist the school in a voluntary capacity from time to
          time.
        </p>
        <p>
          <b> 16.</b> Parents/guardians/visitors are obliged to make contact
          with any student and/or staff during school hours through the school
          office; solely for the safety of our students and staff.
        </p>
        <p>
          <b> 17.</b> All students must comply with the school rules as
          prescribed in the Amity College Student Handbook, which can be
          obtained from the school.
        </p>
        <p>
          <b> 18.</b> The school reserves the right to exclude any student,
          either permanently or temporarily, at any time without notice if the
          Principal or Board in their absolute discretion consider this as
          appropriate. This could include, but not limited to:
        </p>
        <div className="pl-2 lg:pl-6">
          <p>
            • A serious breach of the school’s rules and regulations or codes of
            conduct;
          </p>
          <p>
            • Conduct prejudicial to the repetition or well-being of the school,
            its students or staff;
          </p>
          <p>• A student failing to make satisfactory progress;</p>
          <p>
            • Non-payment of fees and charges or other breaches of these
            conditions of enrolment; or
          </p>
          <p>
            • A breakdown of the relationship of cooperation and trust between
            the parents or guardians of a{" "}
          </p>
          <p>student and the school.</p>
        </div>
        <p>
          The school will not be liable to the family for any loss or damage the
          family suffers because the College exercised its rights under this
          clause.
        </p>
        <p>
          <b> 19.</b> All students must wear the school uniform as prescribed by
          the Student Handbook. Students who fail to come to school with proper
          uniform or appearance may be excluded from attending classes until the
          issue is resolved.
        </p>
        <p>
          <b> 20.</b> Students are not allowed to use mobile phones for any
          purpose either as a phone, camera or player during school hours within
          the school premises. Failure to comply with this policy may see the
          technological devices confiscated for a return to the
          parents/guardians.
        </p>
        <p>
          <b> 21.</b> A bag/locker search may be conducted if there is a
          reasonable cause/concern.
        </p>

        <p>
          <b> 22.</b> Students are expected to take care of their belongings.
          The school will not be responsible for items lost/stolen/damaged at
          school. Stealing will not be tolerated. Cost of a wilful damage to
          another student’s property will be reflected in the family account of
          the student responsible, as well as disciplinary action being taken.
        </p>
        <p>
          <b> 23. </b>Students are allowed to use the school’s learning
          technologies (computer/internet). Each student should be responsible
          (with teacher support) for their own computer folders/files and should
          not interfere with anyone else’s work or with the software programs on
          any computer. Students’ accounts may be checked by the school IT
          administration for security and safety reasons if the need arises.
          Withdrawal of access to computers/internet (and other possible
          consequences) may occur if a student uses this technology in an
          inappropriate manner.
        </p>
        <p>
          <b> 24. </b>All students in Years 7-12 are to bring a computing device
          (iPad, etc) to school every day. This device should only be used by
          the student who owns it. Students must connect their device to the
          designated wireless network supplied by Amity College on school
          grounds. Access to the device and school network is strictly for
          educational purposes. Any other use is forbidden. Students must use
          their device in accordance with the ICT Usage Policy.
        </p>
        <p>
          <b> 25. </b>Promotion from year to year and retention in the school
          depend upon academic and behavioural progress and conduct of the
          student.
        </p>
        <p>
          <b> 26.</b> Prompt notice (within maximum one month of such change) to
          the school is required in case of any change to:
        </p>
        <div className="pl-2 lg:pl-6">
          <p>a. address and contact details;</p>
          <p>b. family, guardianship and/or custody arrangements;</p>
          <p>c. health condition of the student;</p>
          <p>
            d. new special needs/disability that was not in existence at the
            time of enrolment.
          </p>
          <div className="pl-2 lg:pl-6">
            <p>
              • The details provided above will be used to communicate with you
              via text messages or e-mail when necessary. All notices will be
              sent to parents/guardians using the latest contact details
              notified to the school. Hence, all such notices shall be deemed to
              be received by parents/guardians.
            </p>
            <p>
              • The school will not be responsible for any damages/costs in case
              of parent/guardian failure to inform the school about the above
              changes.
            </p>
          </div>
        </div>
        <p>
          <b> 27.</b> School bus services are operated by a private bus company.
          All applications are to be made to them at the school reception. Bus
          routes or pick up/drop off points are subject to change by the bus
          service management at the start of each term, due to new
          applications/cancellations
        </p>
        <p>
          <b> 28.</b> Continuous misconduct of a student on public and/or
          private school buses may result in short/long term suspensions from
          the bus services.{" "}
        </p>
        <p>
          <b> 29.</b> School reports are sent to the address provided by
          parents/guardians. When parents are separated or divorced, reports may
          be sent to both parents upon request, unless there is an order of a
          court, or an agreement which provides that reports are to be sent to
          one parent.{" "}
        </p>
        <Button block type="primary" className="text-left mb-2" size="large">
          FEES AND CHARGES
        </Button>

        <p>
          <b> 30.</b> The Amity College tuition fee is an annual fee which
          becomes due and payable upon enrolment or continued attendance to a
          new tuition year. For administrative purposes, the fee is charged to
          family accounts over four terms (excluding year 12 students). School
          fees must be paid within the first two weeks of each term. An
          administration fee will be charged to family accounts for late
          payments.{" "}
        </p>
        <p>
          <b> 31.</b> For all new enrolments, when signing the enrolment
          contract, parents must pay the enrolment fee and security bond upfront
          to secure enrolment placement. The enrolment fee is not refundable.
          The security bond will not be refunded if a parent decides to cancel,
          defer or withdraw his/her child/children from the school within 12
          months of enrolment. The security bond is refundable when leaving
          after 12 months of enrolment provided there is no debt owing to the
          College.{" "}
        </p>
        <p>
          <b> 32.</b> Tuition fees do not cover uniforms, textbooks, subject and
          technology levies, excursions, transport fee, and optional
          accomplishments e.g. tutoring, sports registration or coaching. The
          cost of subject levies, select textbooks, excursions, incursions,
          camps and other activities will be charged to your family accounts to
          reduce cash handling.{" "}
        </p>
        <p>
          <b> 33.</b> If unforeseen short term difficulty is experienced in
          school fee payment, the parent should notify the Director of Finance
          in writing as soon as possible to resolve the situation.{" "}
        </p>
        <p>
          <b> 34.</b> The School Bus Services fee is payable upfront for the
          following term. A student will not be admitted at the commencement of
          a term if the bus fee for the term remains unpaid.{" "}
        </p>
        <p>
          <b> 35.</b> The cost of excursions, incursions, camps and other
          activities may be added to the family account during the year unless
          an alternative payment is requested by the school administration.{" "}
        </p>
        <p>
          <b>36.</b> Students may be required to sit for national or state tests
          like NAPLAN. The school will charge the cost of these tests or
          competitions to the family accounts.{" "}
        </p>
        <p>
          <b>37.</b> Parents/guardians will be liable for payment resulting from
          any damages caused by their children on the school grounds,
          excursions, camps or associated properties. Wilful damages will be
          regarded as vandalism and dealt with seriously by the school
          administration.{" "}
        </p>
        <p>
          <b>38.</b> No remission of fees either in whole or in part shall be
          made should the student be absent from the school for any reason.{" "}
        </p>
        <p>
          <b>39.</b> Where a parent/guardian wishes to withdraw a student from
          the school, a full term’s notice, in writing to the Principal is
          required. A full term’s fee in lieu of notice will be charged for
          student withdrawals if sufficient notice is not received. A Student
          Exit Form, with clarification of future educational destination, must
          also be completed when terminating the enrolment.{" "}
        </p>
        <p>
          <b>40.</b> If a student’s enrolment is terminated by the school
          administration during a term, the school is entitled to retain or be
          paid, as the case may be, that term’s full fee.{" "}
        </p>
        <p>
          <b>41.</b> The collection process for any outstanding fees may involve
          placing the matter in the hands of a collection agency and taking
          further legal action.{" "}
        </p>
        <p>
          <b>42.</b> The school may impose a building levy, from time to time,
          to help pay for school building and capital projects.{" "}
        </p>
        <p>
          <b>43.</b> School fees are reviewed annually by the School Board.{" "}
        </p>
        <Button block type="primary" className="text-left mb-2" size="large">
          DEFAULT AND CONSEQUENCES
        </Button>

        <p>
          <b>44.</b> If the family defaults in payment of any invoice when due,
          the family shall indemnify the school from and against all of the
          school’s costs and disbursements, including solicitor costs and all of
          the nominated collection agency’s costs of collection.{" "}
        </p>
        <p>
          <b>45.</b> Without prejudice to any other remedies, if at any time the
          family is in breach of any obligation (including those relating to
          payment); the school may suspend or terminate the supply of goods
          and/or services to the family and any of its other obligations under
          the terms and conditions. The school will not be liable to the family
          for any loss or damage the family suffers because the school exercised
          its rights under this clause.{" "}
        </p>
        <p>
          <b>46.</b> In the event that any money payable to the school becomes
          overdue, or in the school’s opinion the family will be unable to meet
          its payments as they fall due, then:{" "}
        </p>
        <div className="pl-2 lg:pl-6">
          <p>
            a. the school shall be entitled to terminate the enrolment contract,
            and{" "}
          </p>
          <p>
            b. all amounts owing to the school shall, whether or not due for
            payment, immediately become due and payable.{" "}
          </p>
        </div>
        <p>
          <Button block type="primary" className="text-left mb-2" size="large">
            PRIVACY
          </Button>
          <b>47.</b> The family (parent or guardian of enrolled student) agrees
          that personal data provided may be used and retained by the school for
          the following purposes, and other purposes as shall be agreed between
          the family and the school or required by law from time to time:{" "}
        </p>
        <div className="pl-2 lg:pl-6">
          <p>
            a. conveying relevant information to the government or its
            representative authorities and to education authorities to comply
            with statutory and/or legal obligations;{" "}
          </p>
          <p>b. provision of services and goods by the school; </p>
          <p>c. marketing of services and/or goods by the school; </p>
          <p>
            d. processing of any payment instructions, direct debit facilities
            and/or credit facilities requested by the family; and{" "}
          </p>
          <p>
            e. enabling the daily operation of the family’s account and the
            collection of amounts outstanding in the family’s account in
            relation to the services and goods.{" "}
          </p>
        </div>
        <p>
          <b>48.</b> The school may give information about the family to a
          credit reporting agency for the following purposes:{" "}
        </p>
        <div className="pl-2 lg:pl-6">
          <p>
            a. to notify other credit providers of a default by the family; and{" "}
          </p>
          <p>
            b. to facilitate the collection of amounts outstanding in the
            family’s account.{" "}
          </p>
        </div>
        <p>
          <b>49.</b> The family consents to the school being given a consumer
          credit report to collect overdue payment on commercial credit (Section
          18K (1) (h) Privacy Act 1988).{" "}
        </p>
        <p>
          <b>50.</b> The school may publish photographs of your child and/or
          samples of work completed by your child and use the still/running
          images and/or voice of your child for promotional or educational
          purposes. This could include, but are not limited to, school
          newsletters (online and in hard copy), Amity College Internet or
          intranet websites, social media, school magazines, yearbook, local or
          community newspapers, banners, media releases and TV/radio
          programs/advertisements. If you do not agree with this statement
          please provide a signed letter stating so to the school immediately.{" "}
        </p>
        <Button block type="primary" className="text-left mb-2" size="large">
          CONSENT FOR EMERGENCY TREATMENT AND EXCURSIONS
        </Button>

        <p>
          <b>51.</b> It is a condition of enrolment that consent is given by
          parents/guardians for a student to take part in any excursion held for
          the purpose of furthering the study of any course or subject taught by
          the school, or as part of a program of tutorial activities or for any
          other purpose approved by the Principal, provided that the
          excursion/incursion/swimming classes/camps or other school activities
          will be under the care and direction of a teacher or teachers of the
          school. This consent applies equally and fully whether participation
          in the excursion by the student is voluntary or compulsory. A detailed
          information letter will be issued prior to such an activity. If
          parents/guardians have any concerns about the activity, they may
          notify the school administration in writing.{" "}
        </p>
        <p>
          <b>52.</b> Students misbehaving in excursions or in-school activities
          may be excluded from similar activities as part of their punishment.{" "}
        </p>
        <p>
          <b>53.</b> In the event of accident or sudden illness where the
          student is under the school’s care, the school will endeavour to
          contact parents as soon as possible but without impeding the school in
          taking action as set out below:{" "}
        </p>
        <p>
          If judged necessary by the school, and whether or not contact has been
          made, and without thereby subjecting the school to liability in
          consequence of any such decision, a student may be taken for emergency
          treatment to a hospital or doctor, or may be given first aid. When
          contact is made with the parents, arrangements may be made for the
          parents to take over and direct their child’s medical care
          accordingly, whereupon the school will cease to be involved in that
          care. Any expenses incurred in connection with such treatment will be
          met by the parents/guardians. This section does not of itself impose
          any liability or obligation on the school.{" "}
        </p>

        <p>
          <b>54.</b> These Conditions of Enrolment are subject to change from
          time to time by the Amity College Management and School Board.{" "}
        </p>
      </div>
      <Button
        type="primary"
        icon={<ArrowLeftOutlined />}
        onClick={() => router.goBack()}
      >
        Back
      </Button>
    </div>
  );
};

export default Conditions;
