import { axios } from "./HttpClient";

export const AdminService = {
  newStudent: (values) => {
    return axios.post("/students", values);
  },
  newExperience: (values) => {
    return axios.post("/steps/experience", values);
  },
  newFamily: (values) => {
    return axios.post("/steps/family", values);
  },
  newMedical: (values) => {
    return axios.post("/steps/medical", values);
  },
  newFeedback: (values) => {
    return axios.post("/steps/feedback", values);
  },
  finalizeOrder: (values) => {
    return axios.post("/invoice/sendFinalOrder", values);
  },
  getDashReports: (values) => {
    return axios.get("/reports/dashboard");
  },
  getCampusStudents: (campus) => {
    return axios.get("/campus?campus=" + campus);
  },
  changeCompleted: (id, deger) => {
    return axios.put("/students/" + id + "/completed?deger=" + deger);
  },
  login: (values) => {
    return axios.post("/staff/login", values);
  },
  newStaff: (values) => {
    return axios.post("/staff", values);
  },
  getStaffs: () => {
    return axios.get("/staff");
  },
  changePassword: (password, id) => {
    return axios.put(
      "/staff/changepassword?password=" + password + "&id=" + id
    );
  },
  deleteStaff: (id) => {
    return axios.delete("/staff/" + id);
  },
  getStudentDetails: (id) => {
    return axios.get("/students/" + id);
  },
  newSchool: (values) => {
    return axios.post("/schools", values);
  },
  getSchools: () => {
    return axios.get("/schools");
  },
  deleteSchool: (id) => {
    return axios.delete("/schools/" + id);
  },
  putContactMail: (values) => {
    return axios.post("/students/" + values.studentId + "/contactmail", values);
  },
  getLoginCode: (email) => {
    return axios.get("/students/getpasscode?email=" + email);
  },
  checkPassCode: (values) => {
    return axios.get(
      "/students/checkpasscode?email=" + values.email + "&code=" + values.code
    );
  },
  newResource: (values) => {
    return axios.post("/resources", values);
  },
  getStudentResources: (id) => {
    return axios.get("/students/" + id + "/resources");
  },
  getInvoicePdf: (id) => {
    return axios.get("/invoice/" + id + ".pdf", { responseType: "blob" });
  },
  getStudentXml: (id) => {
    return axios.get("/reports/export/" + id + ".xml", {
      responseType: "blob",
    });
  },
  newContactMessage: (values) => {
    return axios.post("/contacts", values);
  },
  getContactMessages: (id) => {
    return axios.get("/contacts/school/" + id + "/messages");
  },
  getStudentsParents: (id) => {
    return axios.get("/steps/" + id + "/parents");
  },
};
