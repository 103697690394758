import {
  ArrowRightOutlined,
  ClearOutlined,
  CloseOutlined,
  DeleteOutlined,
  LockOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { GlobalContext } from "../context/GlobalContext";

const Users = () => {
  const [staffs, setStaffs] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [form] = Form.useForm();
  const [newUserForm] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [newPanel, setNewPanel] = useState(false);
  const { okullar } = useContext(GlobalContext);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStaffs().then((data) => {
        console.log(data.data);
        setStaffs(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Permitted campuses",
      dataIndex: "schools",
      render: (schools) =>
        okullar.length === schools.length
          ? "ALL SCHOOLS"
          : schools.map((s, index) => (
              <Tag color="blue" key={index} className="block my-1">
                {s.name}
              </Tag>
            )),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Actions",
      render: (item) => (
        <Space>
          <Tooltip title="Change Password" placement="bottom">
            <Button
              onClick={() => goModal(item)}
              type="primary"
              icon={<LockOutlined />}
            />
          </Tooltip>
          <Tooltip title="Delete User" placement="bottom">
            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => confirm(item.id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              {item.role !== "Admin" && (
                <Button type="primary" danger icon={<DeleteOutlined />} />
              )}
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const confirm = (id) => {
    console.log(id);
    AdminService.deleteStaff(id).then((data) => {
      notification.success({ message: "Deleted!" });
      setInitialRender(true);
    });
  };
  const cancel = () => {
    notification.info({ message: "Cancelled!" });
  };
  const onFinish = (v) => {
    console.log(v);
    AdminService.changePassword(v.password, activeUser.id).then((data) => {
      notification.success({ message: "Password changed" });
      onReset();
      setIsVisible(false);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const goModal = (item) => {
    setActiveUser(item);
    setIsVisible(true);
  };
  const finish = (v) => {
    console.log(v);
    AdminService.newStaff(v).then((data) => {
      notification.success({ message: "User added!" });
      reset();
      setInitialRender(true);
      setNewPanel(false);
    });
  };
  const reset = () => {
    newUserForm.resetFields();
  };
  return (
    <div>
      <Button
        onClick={() => setNewPanel(!newPanel)}
        type="primary"
        danger={newPanel ? true : false}
        icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
      >
        {newPanel ? "Cancel" : "Add New User"}
      </Button>
      <Divider orientation="left">
        <span className="font-semibold text-sky-500">
          {newPanel ? "New User Form" : "Saved Users"}
        </span>
      </Divider>
      {activeUser && (
        <Modal
          width={550}
          footer={null}
          visible={isVisible}
          onCancel={() => setIsVisible(false)}
          title={
            <span className="text-base text-sky-500 font-semibold">
              {`Change ${activeUser.name}'s Password`}
            </span>
          }
        >
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            {...GlobalVariables.formLayout}
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please input new password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...GlobalVariables.tailLayout}>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
                <Button htmlType="reset" icon={<ClearOutlined />}>
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      )}
      <div className="max-w-6xl mx-auto">
        {!newPanel && (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={staffs}
            bordered
            loading={staffs === null}
            size="small"
          />
        )}
        {newPanel && (
          <div className="max-w-4xl border-gray-300 border p-4 bg-fafafa">
            <Form
              form={newUserForm}
              onFinish={finish}
              onReset={reset}
              {...GlobalVariables.formLayout}
            >
              <Form.Item
                label="User Fullname"
                name="name"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Type fullname" autoComplete="none" />
              </Form.Item>
              <Form.Item
                label="Permitted campuses"
                name="schools"
                rules={[{ required: true, message: "School is required!" }]}
              >
                <Select placeholder="Please Select" mode="multiple">
                  {okullar
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Please select" style={{ width: 300 }}>
                  <Select.Option key="0" value="Admin">
                    Admin
                  </Select.Option>
                  <Select.Option key="1" value="SchoolAdmin">
                    School Admin
                  </Select.Option>
                  <Select.Option key="2" value="Parent">
                    Parent
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="E-Mail"
                name="email"
                rules={[
                  { required: true, message: "This field is required!" },
                  { type: "email", message: "Wrong E-mail address" },
                ]}
              >
                <Input placeholder="Please Type E-Mail " autoComplete="none" />
              </Form.Item>
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please input new password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password autoComplete="none" />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
