import { Affix, Col, Modal, Row, Steps } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { GlobalContext } from "src/components/context/GlobalContext";
import Conditions from "src/components/home/Conditions";
import Contacts from "src/components/home/Contacts";
import HomePage from "src/components/home/HomePage";
import Occupation from "src/components/home/Occupation";
import SecureBegin from "src/components/home/SecureBegin";
import Step1 from "src/components/home/Step1";
import Step2 from "src/components/home/Step2";
import Step3 from "src/components/home/Step3";
import Step4 from "src/components/home/Step4";
import Step5 from "src/components/home/Step5";
import Step6 from "src/components/home/Step6";
import Step7 from "src/components/home/Step7";
import Step8 from "src/components/home/Step8";
import UploadPass from "src/components/home/UploadPass";

const HomeLayout = () => {
  const { step } = useContext(GlobalContext);
  const { lg } = useBreakpoint();
  return (
    <div>
      {!lg && (
        <div className="login-page">
          <div className=" flex items-center justify-center bg-gray-50 bg-opacity-80 rounded-lg py-6 px-4 sm:px-6 lg:px-8 shadow-lg border border-gray-400">
            <div className="max-w-md w-full space-y-8 text-center">
              <div>
                <img
                  className="mx-auto h-20 w-auto"
                  src={require("../assets/images/logo.png").default}
                  alt="Logo"
                />
                <br />
                <p>
                  Your screen size is very small. <br />
                  Large screen required for this application form.
                  <br />
                  Please connect desktop computer.
                  <br /> Thank you
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      )}
      {lg && (
        <div className="bg-eeeeee pb-6 max-w-full overflow-hidden min-h-screen">
          <div className="container mx-auto border-r border-l border-gray-300 bg-white shadow-lg">
            <Row>
              <Col flex="auto">
                <Switch>
                  <Route path="/home" exact>
                    <Redirect to="/home/homepage" />
                  </Route>
                  <Route path="/home/homepage">
                    <HomePage />
                  </Route>
                  <Route path="/home/conditions">
                    <Conditions />
                  </Route>
                  <Route path="/home/step1">
                    <Step1 />
                  </Route>
                  <Route path="/home/step2">
                    <Step2 />
                  </Route>
                  <Route path="/home/step3">
                    <Step3 />
                  </Route>
                  <Route path="/home/step4">
                    <Step4 />
                  </Route>
                  <Route path="/home/step5">
                    <Step5 />
                  </Route>
                  <Route path="/home/step6">
                    <Step6 />
                  </Route>
                  <Route path="/home/step7">
                    <Step7 />
                  </Route>
                  <Route path="/home/step8">
                    <Step8 />
                  </Route>
                  <Route path="/home/occupation">
                    <Occupation />
                  </Route>
                  <Route path="/home/begin">
                    <SecureBegin />
                  </Route>

                  <Route>
                    <Redirect to="/home" />
                  </Route>
                </Switch>
              </Col>
              {step > -1 && (
                <Col flex="250px">
                  <div className="h-44 "></div>
                  <Affix offsetTop={10} className="ml-6">
                    <Steps current={step} direction="vertical" size="small">
                      <Steps.Step
                        title="Step-1"
                        description="Student Details"
                      />
                      <Steps.Step
                        title="Step-2"
                        description="Schooling Experience"
                      />
                      <Steps.Step title="Step-3" description="Family Details" />
                      <Steps.Step
                        title="Step-4"
                        description="Medical Information"
                      />
                      <Steps.Step
                        title="Step-5"
                        description="Declaration & Statement of Commitment"
                      />
                      <Steps.Step
                        title="Step-6"
                        description="Payment of Application Fee"
                      />
                      <Steps.Step
                        title="Step-7"
                        description="Parent / Guardian Feedback"
                      />
                      <Steps.Step title="Step-8" description="Finish" />
                    </Steps>
                  </Affix>
                </Col>
              )}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeLayout;
