import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const Documents = () => {
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [resources, setResources] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStudentResources(id).then((data) => {
        console.log(data.data);
        setResources(data.data);
      });
    }
  }, [id, initialRender]);

  const columns = [
    {
      title: "Description",
      dataIndex: "resources",
      render: (resources) => resources.description,
    },
    {
      title: "Date",
      dataIndex: "resources",
      render: (resources) =>
        moment(resources.createdOn).format("DD-MM-YY HH:mm"),
    },
    {
      title: "Number of Files",
      dataIndex: "resources",
      render: (resources) => resources.files.length,
    },
  ];
  const nested = [
    {
      title: "File Name",
      dataIndex: "name",
    },
    {
      title: "File Type",
      dataIndex: "type",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Download",
      dataIndex: "url",
      render: (url) => (
        <Button
          size="small"
          onClick={() =>
            window.open(process.env.REACT_APP_FILES_BASEURL + url, "_blank")
          }
          type="primary"
          icon={<DownloadOutlined />}
        />
      ),
    },
  ];
  return (
    <div className="max-w-5xl mx-auto">
      <Button
        type="primary"
        icon={<ArrowLeftOutlined />}
        onClick={() => router.goBack()}
      >
        Back
      </Button>
      {resources && (
        <Divider orientation="left">
          <span className="text-base text-sky-500 font-semibold">
            {resources[0].fullname} - Uploaded Documents
          </span>
        </Divider>
      )}
      <Table
        expandable={{
          expandedRowRender: (record, index, indent, expanded) => (
            <Table
              dataSource={resources[index].resources.files}
              columns={nested}
              rowKey={id}
              pagination={false}
              size="small"
              className="nested"
            />
          ),
        }}
        dataSource={resources}
        bordered
        loading={resources === null}
        columns={columns}
      />
    </div>
  );
};

export default Documents;
