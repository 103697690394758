import {
  ArrowRightOutlined,
  ClearOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { GlobalContext } from "../context/GlobalContext";
import TitleBar from "./TitleBar";
import SignaturePad from "react-signature-canvas";

const Step5Form = () => {
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const router = useHistory();
  const [selecteds, setSelecteds] = useState([]);
  const { setStep, step, studentId, contact } = useContext(GlobalContext);
  const [imageURL, setImageURL] = useState(null);
  const [sigModal, setSigModal] = useState(false);
  const sigCanvas = useRef({});

  /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
  const clear = () => {
    sigCanvas.current.clear();
    setImageURL(null);
  };
  const close = () => setSigModal(false);

  /* a function that uses the canvas ref to trim the canvas 
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setSigModal(false);
  };
  useEffect(() => {
    if (step === -1) {
      router.push("/");
    }
    setStep(4);
  }, [router, setStep, step]);

  const onFinish = (values) => {
    console.log(values);

    if (imageURL === null) {
      notification.error({ message: "Please sign application" });
      return;
    }
    values = { ...values, studentId, signature: imageURL, contact };
    AdminService.putContactMail(values).then((data) => {
      router.replace("/home/step6");
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const onChange = (s) => {
    console.log(s);
    setSelecteds(s);
  };

  const decs = [
    "I/We certify that the above information given herein by me/us is correct.",
    "I/We understand that submission of “Application for Enrolment” does not guarantee a place for our child.",
    "I/We have read and understood the 'Conditions of Enrolment'.",
    "I/We agree to be bound by these or any regulations of the College which may from time to time be in force.",
    "I/We also undertake to assist my/our child in upholding the ethos of Amity College and agree to co-operate with the College authorities in supporting the school rules and regulations",
  ];
  return (
    <div className="max-w-full mx-auto  lg:max-w-4xl bg-f5f5f5  p-2 lg:p-6 lg:border lg:border-gray-300 ">
      <Modal visible={sigModal} onCancel={close} footer={null} closable={false}>
        <div>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          {/* Button to trigger save canvas image */}
          <Space className="mt-3">
            <Button type="primary" icon={<SaveOutlined />} onClick={save}>
              Save
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200"
              icon={<ClearOutlined />}
              onClick={clear}
            >
              Clear
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200 text-red-600"
              onClick={() => setSigModal(false)}
              icon={<CloseOutlined />}
            >
              Close
            </Button>
          </Space>
        </div>
      </Modal>

      <TitleBar title="DECLARATION & STATEMENT OF COMMITMENT" />
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          paddingRight: "10px",
        }}
      >
        <span>
          Please read the
          <Link className="text-sky-500 font-bold" to="/home/conditions">
            {" "}
            ‘Conditions of Enrolment’{" "}
          </Link>
          thoroughly and check all declarations.
        </span>
        <br />
        <br />
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          {...GlobalVariables.formLayout}
        >
          <Form.Item>
            <Checkbox.Group options={decs} onChange={onChange} />
          </Form.Item>

          <Form.Item label="Signature" required>
            <Button onClick={() => setSigModal(true)} className="text-sky-600">
              Open Signature Pad
            </Button>

            <br />
            <br />

            {imageURL ? (
              <img
                src={imageURL}
                alt="my signature"
                style={{
                  border: "1px solid #ddd",
                  padding: "15px",
                  backgroundColor: "white",
                  width: "150px",
                }}
              />
            ) : null}
          </Form.Item>
          <Form.Item label="Application Date & Time">
            <b> {moment().format("DD MMMM YYYY dddd HH:mm")}</b>
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
            <Space>
              <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={selecteds.length !== decs.length}
              >
                Next <ArrowRightOutlined />
              </Button>
              <Button onClick={() => router.push("/home/step6")}>
                <ArrowRightOutlined />
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Step5Form;
