import React, { useEffect, useContext } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { Link, useHistory } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import Contacts from "./Contacts";

export default function HomePage() {
  const router = useHistory();

  const { setStep, setYol } = useContext(GlobalContext);
  useEffect(() => {
    setStep(-1);
  }, [setStep]);
  const transferFeatures = [
    {
      id: 1,
      name: "APPLICATION",
      description: (
        <span>
          Please read the
          <Link className="text-sky-500 font-bold" to="/home/conditions">
            ‘Conditions of Enrolment’{" "}
          </Link>
          thoroughly. Complete an online of this form. Submit the completed form
          with payment of the application fee.
        </span>
      ),
    },
    {
      id: 2,
      name: "ENTRANCE EXAM",
      description:
        "Your child will attend the Entrance Exam on the designated date. Kindergarten applicants will attend a School Readiness Interview and a Class Observation.",
    },
    {
      id: 3,
      name: "INTERVIEW",
      description:
        "If results of the Entrance Exam are successful, you and your child will be invited to a formal interview.",
    },
    {
      id: 4,
      name: "OFFER OF ENROLMENT",
      description:
        "If results of the interview are successful, you will be sent an Offer of Enrolment letter.",
    },
    {
      id: 5,
      name: "ACCEPTANCE",
      description:
        "In order to secure your child’s place, you need to pay the relevant enrolment fees* within a fortnight of receiving the Offer of Enrolment.",
    },
    {
      id: 6,
      name: "COMMENCEMENT",
      description:
        "Prior to commencement, you must attend a compulsory Orientation Day with your child for a smooth transition to the College.",
    },
  ];

  return (
    <div className="py-6  overflow-hidden lg:pb-24">
      <img
        className="relative mx-auto"
        width={200}
        src={require("../../assets/images/logo.png").default}
        alt=""
      />
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-sky-500 sm:text-4xl">
            APPLICATION FOR ENROLMENT
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Please read the details and fill the form
          </p>
        </div>

        <div className="relative mt-12  lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-bold text-gray-700 tracking-tight sm:text-3xl">
              ADMISSION PROCEDURES
            </h3>

            <dl className="mt-10 space-y-4">
              {transferFeatures.map((item, index) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white">
                      <span className="font-bold text-2xl"> {index + 1}</span>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-semibold text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img
              className="relative mx-auto w-full p-2 border border-gray-300 shadow-lg"
              src={require("../../assets/images/start.jpg").default}
              alt=""
            />
          </div>
          <Space direction="vertical">
            <Button
              className="bg-gradient-to-b from-sky-400 to-sky-600 hover:bg-gradient-to-b hover:from-sky-400 hover:to-sky-600 border-2 border-sky-600 hover:border-sky-800"
              type="primary"
              size="large"
              block
              icon={<ArrowRightOutlined />}
              onClick={() => {
                setYol(true);
                router.push("/home/begin");
              }}
            >
              Start a new application
            </Button>
            <Button
              className="mt-2 bg-gradient-to-b from-amazont to-amazonb  border-2 border-amazonl text-black hover:bg-gradient-to-b hover:from-amazont hover:to-amazonb hover:text-black hover:border-amazonl  "
              size="large"
              block
              icon={<ArrowRightOutlined />}
              onClick={() => {
                setYol(false);
                router.push("/home/begin");
              }}
            >
              Update an existing application
            </Button>
          </Space>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>
      </div>
    </div>
  );
}
